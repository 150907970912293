import { v4 as uuid } from "uuid";
import StorageContants from "../config/storage.constants";
import LocalStorage from "../call-sdk/lib/local-storage";
import { UUID } from "../types";

export default function generateDeviceId(): UUID {
  let deviceId = LocalStorage.get(StorageContants.UNIQUE_DEVICE_ID);

  if (!deviceId) {
    deviceId = uuid();
    LocalStorage.set(StorageContants.UNIQUE_DEVICE_ID, deviceId);
  }

  return deviceId;
}
