import styled from "styled-components";
import VideoInput from "../videoInput/VideoInput";

export const ConferanceRoomContainer = styled.div`
  flex-direction: column;
  height: 100%;
  max-width: 100vw;
  justify-self: center;
  align-self: center;
  margin: auto;
  background-color: #000000;

  .remote-video-container {
    width: 100vw;
    position: absolute;
    top: 18%;
    left: 0px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media all and (orientation:landscape) {
      height:100%;
      top: 0px;
      bottom:0px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align:center;
     };
  };
  .team-video-container {
    position: absolute;
    height:88%;
    top: 12px;
    width:100%;
    display:block;
    text-align:center;
    align-self:start;
    @media (orientation:landscape) {
      display:flex;
      flex-direction: row;
      top:0px;
      justify-content: center;
      align-items: center;
      height:100%;
      max-width: 100vw;
      
     };
    };

  };
  .viewStyleSingle
  {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    @media all and (orientation:landscape) {
      width: 50%;
      height: 100vh;
      object-fit: cover;
    };

  };
  .viewStyleTeam
  {
    display:flex;
    height: 37vh;
    width:85%;
    object-fit: cover;
    margin: auto;
    padding: 0px;

    @media (max-height: 700px) {
      height: 35vh;
    };
    @media all and (orientation:landscape) {
      display:flex;
      width: 39vw;
      height: 100vh;
      object-fit: cover;
     
    };
  };
  .isLandscape{
    visibility: hidden;
    @media all and (orientation:landscape) {
      visibility: visible;
    };
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: center;
  display: flex;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  padding: 32px;

  .normalBtn,
  .cancelBtn,
  .offBtn {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    width: 48px;
    height: 48px;
  };
  @media all and (orientation:landscape) {
    right:0;
    top:0;
    width:10vw;
    height:100%;
    padding: 1px;
  }
`;

export const LocalVideo = styled(VideoInput)`
  width: 100%;
  height: 100%;
`;

export const NoVideoViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;