import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as AppRoutes,
  Navigate,
} from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import QRBusiness from "../src/components/index";
import DesktopPageError from "./components/errorPage/DesktopPageError";
import LandingPage from "./components/landingPage/LandingPage";
import Permission from "./components/permissions/Permission";
import Connecting from "./components/connecting/Connecting";
import ConferanceRoom from "./components/room/ConferanceRoom";
import FeedBackPage from "./components/feedback/FeedBack";
import MobileError from "./components/errorPage/MobileError";
import QRReconnect from "./components/Reconnecting";
import QRReverify from "./components/Reverifying";

const Routes = () => {
  if (isMobile || isTablet) {
    return (
      <Router basename={`${process.env.PUBLIC_URL}`}>
        <AppRoutes>
          <Route path="/qr-verifying/:qr_code_id" element={<QRBusiness />} />
          <Route path="/qr-reconnecting" element={<QRReconnect />} />
          <Route path="/qr-reverify" element={<QRReverify />} />
          <Route path="/qr-business" element={<LandingPage />} />
          <Route path="/qr-permission" element={<Permission />} />
          <Route path="/qr-connecting" element={<Connecting />} />
          <Route path="/qr-room" element={<ConferanceRoom />} />
          <Route path="/qr-feedback" element={<FeedBackPage />} />
          <Route path="/qr-error" element={<MobileError />} />
        </AppRoutes>
      </Router>
    );
  } else {
    return (
      <Router basename={`${process.env.PUBLIC_URL}`}>
        <AppRoutes>
          <Route path="/qr-error" element={<DesktopPageError />} />

          {/* Add a wildcard route for any other paths */}
          <Route path="/*" element={<Navigate to="/qr-error" />} />
        </AppRoutes>
      </Router>
    );
  }
};

export default Routes;
