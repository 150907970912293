import axios, { AxiosRequestHeaders } from "axios";
import { UNKNOW_ERROR, NO_INTERNET } from "../config/constants";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default API;

export interface ApiResult {
  error?: string;
}

export interface ApiResponse {
  code: number;
  message: string;
}

export function getErrorMessage(error: any) {
  let message;
  if (!navigator.onLine) {
    message = NO_INTERNET;
  } else if (error?.response?.data?.message) {
    message = error.response.data.message;
  } else {
    message = UNKNOW_ERROR;
  }
  return message;
}

export interface AttachRequestInterceptorOptions {
  authToken: string;
}

export function attachRequestInterceptor({
  authToken,
}: AttachRequestInterceptorOptions): number {
  return API.interceptors.request.use((config) => {
    const newConfig = { ...config };
    newConfig.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    } as AxiosRequestHeaders;
    return newConfig;
  });
}

export function detachRequestInterceptor(interceptorId: number) {
  API.interceptors.request.eject(interceptorId);
}
