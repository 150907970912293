import { useCallback, useEffect, useState } from "react";
import { Room, RemoteParticipant } from "twilio-video";
import * as Sentry from '@sentry/react'
 
// Custom hook to get participants from a room
  const useParticipants = (room: Room | null) => {
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
 
  // Define a function to update the participants
  const updateParticipants = useCallback(() => {
    if (room) {
      const participantArray = Array.from(room.participants.values());
      setParticipants(participantArray);

   //log participant count to sentry
      Sentry.captureMessage(`Participants updated:${participantArray.length}`)
      
    //log each participant to sentry
      participantArray.forEach(participant=>{
      Sentry.captureMessage(`Participant currently in room :${participant.identity}`)
    })
    }},[room])
   
  useEffect(() => {
      if (room) {
        updateParticipants(); // Initial update
  
        //listen for participant connected events
        const handleParticipantConnected = (participant:RemoteParticipant)=>{
        Sentry.captureMessage(`participant connected:${participant.identity}`);
          updateParticipants()
        }
       room?.on("participantConnected",handleParticipantConnected);
  
  
       //listen for participnat disconnected events
        const handleParticipantDisconnected = (participant:RemoteParticipant)=>{
        Sentry.captureMessage(`participant disconnected:${participant.identity}`);
        updateParticipants();
       };
       room?.on("participantDisconnected",handleParticipantDisconnected);
  
        // Clean up event listeners when the component unmounts
        return () => {
          room?.off("participantConnected", handleParticipantConnected);
          room?.off("participantDisconnected", handleParticipantDisconnected);
        };
      } 
  
    }, [room,updateParticipants]);
  
    return participants;
 };
 
export default useParticipants;
 