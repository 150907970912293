import styled from "styled-components";

export const SelfViewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  float: right;
  margin: 15px;
  width: 14rem;
  height: 14rem;
  max-width: 10rem;
  max-height: 10rem;

  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
  border-radius: 10px;
  overflow: hidden;
  z-index: 11;

  @media (max-width: 430px) {
    height: 27vw;
    width: 27vw;
    margin: 10px;
  }
  @media (orientation: landscape) {
    height: 14vw;
    width: 14vw;
  }
  @media all and (orientation: landscape) {
    margin-top: 2px;
    float: left;
    // position: fixed;
    // top: 14%;
    // left: 48%;
    // transform: translate(-50%, -50%);
  }
  img {
    transform: scale(1, 1);
  }
  video {
    transform: scale(-1, 1);
  }
`;

export const VideoViewContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const NoVideoViewContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2a2e36;
  display: flex;
  justify-content: center;
  align-items: center;
`;
