import React, { useEffect, useState, useCallback } from "react";
import {
  RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteTrack,
  RemoteParticipant,
  RemoteAudioTrackPublication,
  RemoteVideoTrackPublication,
} from "twilio-video";
import { useCall } from "../../call-sdk/context/CallContext";
import useVideoToggle from "../../hooks/useVideoToggle";
import { useIdentity } from "../../call-sdk/context/IdentityContext/IdentityContext";
import { getParticipantType } from "../../services/qr-participant.service";
import { isChrome } from "src/util/browser.util";
import Video_Off from "../../assets/Room/video-off.svg";
import ErrorBoundary from "../ErrorBoundary";

import { StyledRemoteVideo , NoVideoView , StyledAudio , StyledRemoteParticipantVideo } from "./RemoteParticipantVideoStyles";

function notNull<T>(x: T | null): x is T {
  return x !== null;
}

const getTracksFromPublications = (
  tracks: Array<RemoteAudioTrackPublication | RemoteVideoTrackPublication>
): Array<RemoteAudioTrack | RemoteVideoTrack> => {
  return tracks
    .map(
      (track: RemoteAudioTrackPublication | RemoteVideoTrackPublication) =>
        track.track
    )
    .filter(notNull);
};

// Custom hook to fetch participant type
const useFetchParticipantType = (
  call: any,
  participant: RemoteParticipant,
  isVideoEnabled: boolean
) => {
  const { setRemoteVideoFlag } = useIdentity();

  useEffect(() => {
    const fetchParticipantType = async () => {
      if (call.requestId && participant.identity && !isVideoEnabled) {
        const { requestId } = call;
        const participantId = participant.identity;
        const { data } = await getParticipantType({ requestId, participantId });
        if (data) {
          setRemoteVideoFlag(true);
        }
      }
    };

    if (isChrome()) {
      fetchParticipantType();
    }
  }, [call, participant.identity, isVideoEnabled, setRemoteVideoFlag]);
};

// Custom hook to manage audio and video tracks
const useTrackManagement = (participant: RemoteParticipant) => {
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);
  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);

  const handleTrackSubscribed = useCallback((track: RemoteTrack) => {
    console.log("handleTrackSubscribed");
    if (track.kind === "audio") {
      setAudioTracks((tracks) => [...tracks, track]);
    } else if (track.kind === "video") {
      setVideoTracks((tracks) => [...tracks, track]);
    }
  }, []);

  const handleTrackUnsubscribed = useCallback((track: RemoteTrack) => {
    console.log("handleTrackUnsubscribed");
    if (track.kind === "audio") {
      setAudioTracks((tracks) => [
        ...tracks.filter((_track) => _track !== track),
      ]);
    } else if (track.kind === "video") {
      setVideoTracks((tracks) => [
        ...tracks.filter((_track) => _track !== track),
      ]);
    }
  }, []);

  useEffect(() => {
    const participantAudioTracks = getTracksFromPublications([
      ...participant.audioTracks.values(),
    ]);
    setAudioTracks(participantAudioTracks as RemoteAudioTrack[]);

    const participantVideoTracks = getTracksFromPublications([
      ...participant.videoTracks.values(),
    ]);
    setVideoTracks(participantVideoTracks as RemoteVideoTrack[]);
    participant.on("trackSubscribed", handleTrackSubscribed);

    participant.on("trackUnsubscribed", handleTrackUnsubscribed);

    return () => {
      participant.off("trackSubscribed", handleTrackSubscribed);
      participant.off("trackUnsubscribed", handleTrackUnsubscribed);
    };
  }, [handleTrackSubscribed, handleTrackUnsubscribed, participant]);

  return { audioTracks, videoTracks };
};

export interface RemoteParticipantVideoProps {
  participant: RemoteParticipant;
  onMessage?: (text: string) => void;
  viewStyle: string;
}

const RemoteParticipantVideo: React.FC<RemoteParticipantVideoProps> = ({
  participant,
  onMessage,
  viewStyle,
}) => {
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null
  );
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );

  const call = useCall();

  const { audioTracks, videoTracks } = useTrackManagement(participant);
  const { isVideoEnabled } = useVideoToggle(videoTracks[0]);
  useFetchParticipantType(call, participant, isVideoEnabled);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack && audioElement) {
      audioTrack.attach(audioElement);
    }

    return () => {
      audioTrack?.detach();
    };
  }, [audioTracks, audioElement]);

  useEffect(() => {
    const videoTrack = videoTracks[0];

    if (videoTrack && videoElement) {
      videoTrack.attach(videoElement);
    }

    return () => {
      videoTrack?.detach();
    };
  }, [videoTracks, videoElement]);

  return (
    <ErrorBoundary>
      <StyledRemoteParticipantVideo>
        {!isVideoEnabled ? (
          <NoVideoView className={viewStyle}>
            <img src={Video_Off} alt="No Camera" />
          </NoVideoView>
        ) : (
          <StyledRemoteVideo
            ref={(video) => setVideoElement(video)}
            autoPlay
            controls={false}
            className={viewStyle}
          />
        )}
        <StyledAudio
          ref={(audio) => setAudioElement(audio)}
          autoPlay
          muted={false}
        />
      </StyledRemoteParticipantVideo>
    </ErrorBoundary>
  );
};

export default RemoteParticipantVideo;
