import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";

export interface MuteOptions {
  roomname: string;
}

export interface MuteResponse extends APIResponse {
  data: null;
}

export interface MuteResult extends APIResult {
  data?: {
    message: string;
  };
}

export async function muteVoiceConference(
  options: MuteOptions
): Promise<MuteResult> {
  const result: MuteResult = {};

  try {
    const { data: responseData } = await axios.post<MuteResponse>(
      END_POINT.QR_MUTE_USER_VOICE_CONFERENCE,
      options
    );
    if (responseData) {
      result.data = { message: responseData.message };
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }

  return result;
}

export interface UnmuteOptions {
  roomname: string;
}

export interface UnmuteResponse extends APIResponse {
  data: null;
}

export interface UnmuteResult extends APIResult {
  data?: {
    message: string;
  };
}

export async function unmuteVoiceConference(
  options: UnmuteOptions
): Promise<UnmuteResult> {
  const result: UnmuteResult = {};

  try {
    const { data: responseData } = await axios.post<UnmuteResponse>(
      END_POINT.QR_UNMUTE_USER_VOICE_CONFERENCE,
      options
    );
    if (responseData) {
      result.data = { message: responseData.message };
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }

  return result;
}
