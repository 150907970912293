import * as Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

const CHROME = "chrome";
const FIREFOX = "firefox";
const SAFARI = "safari";
const MS_EDGE = "edge";

export function getBrowserName() {
  return browser.getBrowser().name?.toLowerCase();
}

export function isChrome() {
  return getBrowserName() === CHROME;
}

export function isEdge() {
  return getBrowserName() === MS_EDGE;
}

export function isFirefox() {
  return getBrowserName() === FIREFOX;
}

export function isSafari() {
  return getBrowserName() === SAFARI;
}
