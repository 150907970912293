import React from "react";
import VideoInput from "../videoInput/VideoInput";
import { useTwilioUserMediaContext } from "../../call-sdk/context/TwilioUserMediaContext";
import { localTrackToMediaStream } from "../../util/local-track.util";

const VideoView: React.FunctionComponent = () => {
  const { videoTracks } = useTwilioUserMediaContext();

  const videoTrack = videoTracks[0];

  return (
    <>
      {videoTrack ? (
        <VideoInput
          mediaStream={localTrackToMediaStream(videoTrack)}
          className="video-container"
          resize
        />
      ) : (
        "vedio not supported"
      )}
    </>
  );
};

export default VideoView;
