import React, {
  useCallback,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from "react";
import { useTwilioUserMediaContext } from "../TwilioUserMediaContext";

export interface SelfViewContextValues {
  turnedOff: boolean;
  turnOff: () => void;
  turnOn: () => void;
  destroy: () => void;
}

const defaultValues: SelfViewContextValues = {
  turnedOff: true,
  turnOff: () => {},
  turnOn: () => {},
  destroy: () => {},
};

export const SelfViewContext =
  React.createContext<SelfViewContextValues>(defaultValues);

export const SelfViewConsumer = SelfViewContext.Consumer;

export const SelfViewProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const { audioDeviceId, videoDeviceId, startMedia, stopMedia } =
    useTwilioUserMediaContext();

  const [turnedOff, setTurnedOff] = useState(true);

  const turnOff = useCallback(() => {
    stopMedia();
    setTurnedOff(true);
  }, [stopMedia]);

  const turnOn = useCallback(() => {
    startMedia({
      type: "exact",
      audioDeviceId: audioDeviceId ?? "",
      videoDeviceId: videoDeviceId ?? "",
    });
    setTurnedOff(false);
  }, [audioDeviceId, videoDeviceId, startMedia]);

  const destroy = useCallback(() => {
    setTurnedOff(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      turnedOff,
      turnOff,
      turnOn,
      destroy,
    }),
    [turnedOff, turnOff, turnOn, destroy]
  );

  return (
    <SelfViewContext.Provider value={contextValue}>
      {children}
    </SelfViewContext.Provider>
  );
};

export const useSelfViewContext = () => useContext(SelfViewContext);
