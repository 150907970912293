import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { UUID, APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";

export interface HangupCallResult extends APIResult {
  data?: {
    message: string;
  };
}

export interface HangupCallResponse extends APIResponse {
  data: null;
}

export interface HangupCallOptions {
  roomname: UUID;
}

export default async function qrHangupCall(options: HangupCallOptions) {
  const result: HangupCallResult = {};
  try {
    const { data: responseData } = await axios.post<HangupCallResponse>(
      END_POINT.QR_HANGUP_CALL,
      options
    );
    if (responseData) {
      if (responseData.code === 0 || responseData.code === 1 || responseData.code === 2) {
        result.data = {
          message: responseData.message,
        };
      } else {
        result.error = responseData.message;
      }
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
}
