import React, { useEffect } from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import WebFontLoader from "webfontloader";
import { ToastProvider } from "./context/ToastContext";
import { CallProvider } from "./call-sdk/context/CallContext";
import { TwilioUserMediaProvider } from "./call-sdk/context/TwilioUserMediaContext";
import { IdentityProvider } from "./call-sdk/context/IdentityContext";
import { RoomProvider } from "./call-sdk/context/RoomContext/RoomContext";
import Routes from "./Routes";
import AxiosInterceptors from "./components/AxiosInterceptors/AxiosInterceptors";

const theme = createTheme({
  // Customize your theme here if needed
});

function App() {
  useEffect(() => {
    WebFontLoader.load({
      google: {
        families: ["Source Sans Pro:300,400,600,700", "sans-serif"],
      },
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <TwilioUserMediaProvider>
          <CallProvider>
            <RoomProvider>
              <IdentityProvider>
                <ToastProvider>
                  <AxiosInterceptors>
                    <Routes />
                  </AxiosInterceptors>
                </ToastProvider>
              </IdentityProvider>
            </RoomProvider>
          </CallProvider>
        </TwilioUserMediaProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
