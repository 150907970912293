import styled from "styled-components";
export const  StyledRemoteParticipantVideo = styled.div`
  // display: flex;
  // flex-direction: column;
`;

export const  StyledRemoteVideo = styled.video`
  // width: 100vw;
  // height: 60vh;
  // object-fit: cover;
`;

export const  NoVideoView = styled.div`
  position: relative;
  height: 38vh;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const  StyledAudio = styled.audio`
  display: none !important;
`;