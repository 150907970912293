const Messages = {
  NETWORK_ERROR: "Network Error",
  TOKEN_EXPIRED: "Session Expired. Please login again.",
  UNKNOW_ERROR: "Some error occurred. Please try again later.",
  NO_INTERNET: "No Internet",
  DEVICE_SETTING_TITLE: "Devices Settings",
  DEVICE_SETTING: {
    CAMERA: {
      TITLE: "Camera",
    },
    MICROPHONE: {
      TITLE: "Microphone",
    },
    SPEAKER: {
      TITLE: "Speaker",
    },
  },
  CAMERA_MICROPHONE_BLOCKED: `
        Camera is blocked. To make and receive calls,<br/>
        please allow camera access in your browser settings and reload this page.
    `,
  CAMERA_MICROPHONE_DISABLED: `
        Camera is currently disabled. To make and receive calls,<br/>
        please enable the camera below.
    `,
  CAMERA_MICROPHONE_USED_BY_OTHER_APP: `
        Your camera is in use by another app. To use the camera,<br/>
        please quit the other app using video and reload this page.
    `,
  INCOMING_CALL_CAMERA_BLOCKED: `
        Camera is blocked. To answer this call,<br/>
        please allow camera access in your browser settings.
    `,
  INCOMING_CALL_CAMERA_USED_BY_OTHER_APP: `
        Camera is in use by another app. To answer this call, <br/>
        please quit the other app using video.
    `,
  VCO_MICROPHONE_BLOCKED: `
    Microphone is blocked. To use VCO, please end this call and allow access to the microphone in your browser settings and reload the page.
    `,
  VCO_SETTINGS_MICROPHONE_BLOCKED: `
    Microphone is blocked. To use VCO, please allow access to the microphone in your browser settings and reload this page.
    `,
  CAMERA_NOT_CONNECTED: `The camera is not connected. To make and receive calls, please connect your camera and reload this page.`,
  INCOMING_CALL_CAMERA_NOT_CONNECTED: `The camera is not connected. To answer incoming calls, please connect your camera.`,
  PASSWORD_INFO:
    "Password must have at least 8 characters, including 1 capital letter, 1 number and 1 special character.",
  CAMERA_TURNED_OFF: "Camera is turned off.",
  TOKEN_ERROR: `Connection with the server failed. Trying to reconnect. Please wait...`,
  FEEDBACK_MESSAGE: `Please wait...`,
  FEEDBACK_END_MESSAGE: `Your call has ended.`,
};

export default Messages;
