import axios from "axios";
import { QrAuthRequest, UUID, APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";
import END_POINT from "../config/endpoints.constants";

interface AuthLoginState {
  businessId: string;
  uuid: UUID;
  token: string;
}
export interface AuthLoginResult extends APIResult {
  data?: {
    user: AuthLoginState;
  };
}

export interface AuthLogin extends APIResponse {
  data: AuthLoginState;
}

export async function authLogin(
  qrAuthRequest: QrAuthRequest
): Promise<AuthLoginResult> {
  const result = {} as AuthLoginResult;
  try {
    const { data: responseData } = await axios.post<AuthLogin>(
      END_POINT.QR_AUTH_LOGIN,
      qrAuthRequest
    );
    if (responseData?.data) {
      result.data = { user: responseData.data };
    } else {
      result.error = responseData.message;
    }
  } catch (error: any) {
    console.log("catch", error);
    result.error = getErrorMessage(error);
  }
  return result;
}

export interface LogoutOptions {
  deviceId: UUID;
}
export interface LogoutResult extends APIResult {
  data?: {
    message: string;
  };
}
export interface LogoutResponse extends APIResponse {
  data: null;
}

export async function authlogout(
  options: LogoutOptions
): Promise<LogoutResult> {
  const result: LogoutResult = {};
  try {
    const { data: responseData } = await axios.post<LogoutResponse>(
      END_POINT.QR_LOGOUT,
      options
    );
    if (responseData) {
      result.data = { message: responseData.message };
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
}
