import { Snackbar, SnackbarOrigin } from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { createContext, useCallback, useContext, useState } from "react";

type ToastOptions = {
  // timeout: number;
  origin: SnackbarOrigin;
};

type ToastSuccess = (message: string, options?: ToastOptions) => void;
type ToastError = (message: string, options?: ToastOptions) => void;

type ToastContextValue = {
  success: ToastSuccess;
  error: ToastError;
};

export const ToastContext = createContext<ToastContextValue>({
  success: () => {},
  error: () => {},
});

type ToastMessage = {
  id: string;
  type: "success" | "error";
  message: string;
  options: ToastOptions;
  open: boolean;
};

const defaultOptions: ToastOptions = {
  // timeout: 5000,
  origin: {
    horizontal: "center",
    vertical: "top",
  },
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const success: ToastSuccess = useCallback(
    (message, options = defaultOptions) => {
      const newMessage: ToastMessage = {
        id: Date.now().toString(),
        type: "success",
        message,
        options,
        open: true,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    },
    []
  );

  const error: ToastError = useCallback((message, options = defaultOptions) => {
    const newMessage: ToastMessage = {
      id: Date.now().toString(),
      type: "error",
      message,
      options,
      open: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }, []);

  const onClose = (messageId: string) => {
    const filteredMessages = messages.filter(
      (message) => message.id !== messageId
    );
    setMessages([...filteredMessages]);
  };

  return (
    <ToastContext.Provider value={{ success, error }}>
      {children}
      {messages.map((message) => (
        <Snackbar
          key={message.id}
          open={message.open}
          // autoHideDuration={message.options.timeout}
          onClose={() => onClose(message.id)}
          anchorOrigin={message.options.origin}
        >
          <Alert
            variant="filled"
            severity={message.type}
            onClose={() => onClose(message.id)}
          >
            {message.message}
          </Alert>
        </Snackbar>
      ))}
    </ToastContext.Provider>
  );
};

export const ToastConsumer = ToastContext.Consumer;

export const useToast = () => useContext(ToastContext);
