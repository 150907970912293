import styled from "styled-components";



export const Container = styled.div`
  background-color: black;
  width: 100vw;
  height: 100vh;
`;

export const ConnectingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
  width: 100vw;
  align-items: center;
  gap:9vh;
  @media (orientation: landscape) {
      gap:2vh;
    
  }
`;

export const QueueTextContainer = styled.div`
  color: white;
  text-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  font-family: "Source Sans Pro";
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 118%;
`;

export const EndButtonContainer = styled.div`
  position: absolute;
  width:100%;
  bottom: 0;
  justify-content: center;
  display: flex;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  padding:32px;
  .normalBtn{
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    background: var(--white-100, #FFF);    width: 48px;
    height: 48px;

  };
  .cancelBtn{
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    background: var(--critical-default, #DF352C);
    width: 48px;
    height: 48px;
  };
`;