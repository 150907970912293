import { isAudioDeviceAvailable, isVideoDeviceAvailable } from "./device";
import { getUserMedia } from "./media";
import * as Sentry from "@sentry/react";

export const FRAME_RATE = 24;
export const VIDEO_WIDTH = 1280;
export const VIDEO_HEIGHT = 720;

export function getMediaStreamConstraints(constraints: MediaStreamConstraints) {
  const newConstraints = { ...constraints };

  if (typeof constraints.video === "object") {
    newConstraints.video = {
      ...constraints.video,
      width: VIDEO_WIDTH,
      height: VIDEO_HEIGHT,
      frameRate: FRAME_RATE,
    };
  }

  return newConstraints;
}

export interface GetContraintsOptions {
  audioDeviceId?: string;
  videoDeviceId?: string;
  type?: "ideal" | "exact";
  devices: MediaDeviceInfo[];
}

export async function getContraints({
  type = "ideal",
  audioDeviceId,
  videoDeviceId,
  devices,
}: GetContraintsOptions): Promise<MediaStreamConstraints> {
  const useDefaultAudio =
    typeof audioDeviceId !== "undefined" &&
    !isAudioDeviceAvailable(audioDeviceId, devices);
  const useDefaultVideo =
    typeof videoDeviceId !== "undefined" &&
    !isVideoDeviceAvailable(videoDeviceId, devices);

  const constraints: MediaStreamConstraints = getMediaStreamConstraints({
    audio: useDefaultAudio
      ? { deviceId: "" }
      : { deviceId: { [type]: audioDeviceId } },
    video: useDefaultVideo
      ? { deviceId: "" }
      : { deviceId: { [type]: videoDeviceId } },
  });

  // Check if the browser is a mobile browser or Safari
  const isMobileBrowser =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isMobileBrowser || isSafari) {
    // For mobile browsers and Safari, we need to add 'facingMode' to constraints.video if it's an object
    if (constraints.video && typeof constraints.video === "object") {
      constraints.video.facingMode = useDefaultVideo
        ? "user"
        : { [type]: "environment" };
    }
  }

  if (typeof audioDeviceId === "undefined") {
    delete constraints.audio;
  }
  if (typeof videoDeviceId === "undefined") {
    delete constraints.video;
  }

  try {
    // Use the polyfilled getUserMedia to access media devices
    const stream = await getUserMedia(constraints);
    stream.getTracks().forEach((track) => track.stop()); // Stop the tracks to release the resources
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }

  return constraints;
}
