import styled from "styled-components";


export const   PermisionContainer = styled.div`
  background-color: black;
  height: 100vh;
  disply: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  widht: 100%;
`;

export const   AccessContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 0px;
  flex: stretch;
  widht: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0.8rem 2rem;

  .oops {
    color: var(--white-100, #fff);
    font-feature-settings: "clig" off, "liga" off;
    /* Heading/H4 Bold */
    font-family: Source Sans Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 118%; /* 37.76px */
    margin-bottom: 16px;
  }
  .instruction {
    color: var(--white-100, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
  }
`;

export const   ButtonContainer = styled.div`
  display: flex;
  margin-top: 48px;
  width: 100%;
  button {
    text-transform: none;
    height: 48px;
    color: var(--white-100, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
  }
`;

export const   HelpContainer = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: center;
`;