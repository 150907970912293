import { makeStyles } from "@mui/styles";
import { ButtonTheme } from "../theme/ColorSystem";

export type ColorTypes =
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "warning"
  | "info";
/**
 * Contained CSS object
 * @param {ColorTypes} color
 */
const getContainedCSS = (color: ColorTypes): any => {
  return {
    backgroundColor: `${ButtonTheme.active[color]}`,
    borderColor: `${ButtonTheme.active[color]}`,
    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: `${ButtonTheme.hover[color]}`,
      borderColor: `${ButtonTheme.hover[color]}`,
    },
    "&:focus": {
      backgroundColor: `${ButtonTheme.focus[color]}`,
      borderColor: `${ButtonTheme.focus[color]}`,
      outline: "none",
    },
    "&:disabled": {
      backgroundColor: `${ButtonTheme.disabled[color]}`,
      borderColor: "transparent",
      cursor: "not-allowed",
      boxShadow: "none",
      color: "#fff",
    },
  };
};
/**
 * Outline CSS object
 * @param {ColorTypes} color
 */
const getOutlineCSS = (color: ColorTypes): any => {
  return {
    borderColor: `${ButtonTheme.active[color]}`,
    color: `${ButtonTheme.active[color]}`,
    "&:hover": {
      borderColor: `${ButtonTheme.hover[color]}`,
      color: `${ButtonTheme.hover[color]}`,
    },
    "&:focus": {
      borderColor: `${ButtonTheme.focus[color]}`,
      color: `${ButtonTheme.focus[color]}`,
      outline: "none",
    },
    "&:disabled, &[disabled]": {
      borderColor: `${ButtonTheme.disabled[color]}`,
      color: `${ButtonTheme.disabled[color]}`,
      opacity: 0.3,
      cursor: "not-allowed",
    },
  };
};

export const useStyles = makeStyles(() => ({
  root: {
    color: "#fff",
    fontWeight: 600,
    textTransform: "uppercase",
    textAlign: "center",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "sans-serif !important",
  },
  btnLarge: {
    padding: "0.7rem 1rem",
    fontSize: "1.25rem",
    borderRadius: "100px",
    borderWidth: "2px",
    borderStyle: "solid",
    height: "56px",
  },
  btnMedium: {
    padding: "0.375rem 1rem",
    fontSize: "1rem",
    borderRadius: "100px",
    borderWidth: "2px",
    borderStyle: "solid",
    height: "40px",
  },
  btnPrimaryContained: getContainedCSS("primary"),
  btnSecondaryContained: getContainedCSS("secondary"),
  btnDefaultContained: getContainedCSS("default"),
  btnDangerContained: getContainedCSS("danger"),
  btnWarningContained: getContainedCSS("warning"),
  btnInfoContained: getContainedCSS("info"),
  btnPrimaryOutline: getOutlineCSS("primary"),
  btnSecondaryOutline: getOutlineCSS("secondary"),
  btnDefaultOutline: getOutlineCSS("default"),
  btnDangerOutline: getOutlineCSS("danger"),
  btnWarningOutline: getOutlineCSS("warning"),
  btnInfoOutline: getOutlineCSS("info"),
  btnFullWidth: {
    width: "100%",
  },
}));
