import React from "react";
import cx from "classnames";
import capitalize from "lodash.capitalize";
import MaterialButton, {
  ButtonProps as MaterialButtonProps,
} from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "./styles";

interface ButtonProps extends MaterialButtonProps {
  component?: any;
  loading?: boolean;
}
/**
 * Button component
 * @param {ButtonProps}  props
 */
const Button = React.forwardRef((props: ButtonProps, ref: React.Ref<any>) => {
  const {
    type = "button",
    color = "primary",
    variant = "contained",
    size = "medium",
    children,
    fullWidth = false,
    className,
    component,
    loading,
    startIcon,
    ...otherProps
  } = props;
  const classes: any = useStyles();
  const btnClassName = cx(
    classes.root,
    classes[`btn${capitalize(size)}`],
    classes[`btn${capitalize(color)}${capitalize(variant)}`],
    {
      [classes.btnFullWidth]: fullWidth,
      "btn-full-Width": fullWidth,
    },
    `btn-${size}`,
    // `btn-${color}`,
    `btn-${variant}`,
    className
  );
  return (
    // eslint-disable-next-line react/button-has-type
    <MaterialButton
      {...otherProps}
      type={type ?? "button"}
      className={btnClassName}
      ref={ref}
      component={component}
      startIcon={
        loading ? <CircularProgress size={20} className="mr-2" /> : startIcon
      }
    >
      {children}
    </MaterialButton>
  );
});

export default Button;
