import React from "react";
import styled from "styled-components";
import { Divider } from "@mui/material";
import BrowserHelp from "./browser/BrowserHelpContent";

const HeadContainer = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 118%; /* 28.32px */
`;

const ContentContainer = styled.div`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Body 1 Regular */
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const AndroidHelpContent = () => {
  return (
    <React.Fragment>
      <HeadContainer>Camera or Microphone is blocked</HeadContainer>
      <ContentContainer>
        If your camera or microphone is blocked, it may be because they don’t
        have the needed permissions to function.
      </ContentContainer>
      <br />
      <ContentContainer>
        To give them permissions to function in Chrome, please follow these
        steps:
      </ContentContainer>
      <br />
      <Divider />
      <br />
      <BrowserHelp />
    </React.Fragment>
  );
};

export default AndroidHelpContent;
