import React from "react";
import styled from "styled-components";
import Websiteettings from "../../../../../assets/Help/IOS/iOS-Safari-WebsiteSettingsMenu.png";
import TryAgain from "../../../../../assets/Help/Buttons/Button-TryAgain.svg";
import Config from "src/config";

const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Body 1 Regular */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const ImgDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgStep1 = styled.img`
  width: 249px;
  height: 40.069px;
  flex-shrink: 0;
`;

const ImgStep2 = styled.img`
  width: 249px;
  height: 373.1px;
  flex-shrink: 0;
`;

const ImgStep3 = styled.img`
  width: 249px;

  flex-shrink: 0;
`;

const TextDiv = styled.div`
  display: flex;
  text-align: left;
`;

const ContentSpan = styled.span`
  padding-left: 4px;
`;

const SafariContent = () => {
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  return (
    <BrowserContainer>
      <>
        <TextDiv>
          <span>1.</span>
          <ContentSpan>
            Tap <span style={{ fontSize: "18px" }}>“</span>
            <span style={{ fontSize: "14px" }}>A</span>
            <span style={{ fontSize: "20px" }}>A</span>
            <span style={{ fontSize: "18px" }}>”</span> icon in browser’s
            address bar.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgStep1
            src={regionConfig.addressBarSafariImageSrc}
            alt="address-bar-img"
          />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>2.</span>
          <ContentSpan>Tap “Website Settings”.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgStep2 src={Websiteettings} alt="open-website-settings" />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>3.</span>
          <ContentSpan>
            Tap “Deny” for Camera & Microphone, then select “Allow”.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgStep3
            height="125.45px"
            src={regionConfig.denyAllowImageSrc}
            alt="deny-then-allow"
          />
        </ImgDiv>
        <br />
        <ImgDiv>
          <ImgStep3
            height="221.122px"
            src={regionConfig.allowCamSettingsImageSrc}
            alt="cam-setting-allow"
          />
        </ImgDiv>
        <br />
        <ImgDiv>
          <ImgStep3
            height="221.122px"
            src={regionConfig.allowMicSettingsImageSrc}
            alt="mic-setting-allow"
          />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>4.</span>
          <ContentSpan>
            Close menu, then tap “Try Again” to restart the process.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgStep3 src={TryAgain} alt="try-again-button" />
        </ImgDiv>
        <br />
      </>
    </BrowserContainer>
  );
};

export default SafariContent;
