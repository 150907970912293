import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { UUID, APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";
import { MakeCallDetail } from "../types/call";

export interface MakeCallResult extends APIResult {
  data?: {
    callDetail: MakeCallDetail;
  };
}

export interface MakeCallResponse extends APIResponse {
  data: MakeCallDetail;
}

export interface MakeCallOptions {
  fromNumber: string;
  specialityTags: string;
  deviceId: UUID;
  isQRRequest: boolean;
}

export default async function qrMakeCall(options: MakeCallOptions) {
  const result: MakeCallResult = {};
  try {
    const { data: responseData } = await axios.post<MakeCallResponse>(
      END_POINT.QR_MAKE_CALL,
      options
    );

    if (responseData?.code === 0) {
      result.data = {
        callDetail: responseData.data,
      };
    } else if (responseData?.code === 1) {
      result.error = responseData.message;
    } else if (responseData?.data) {
      result.data = {
        callDetail: responseData.data,
      };
      result.error = responseData.message;
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
}
