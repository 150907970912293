import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { UUID, APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";
import { CallRequest } from "../types/call";

export interface GetRequestByIdResult extends APIResult {
  data?: {
    callRequest: CallRequest;
  };
}

export interface GetRequestByIdResponse extends APIResponse {
  data: CallRequest;
}

export interface GetRequestByIdOptions {
  id: UUID;
}

export default async function qrRequestById(options: GetRequestByIdOptions) {
  const result: GetRequestByIdResult = {};
  try {
    const { data: responseData } = await axios.post<GetRequestByIdResponse>(
      END_POINT.QR_GET_REQUEST_BY_ID,
      options
    );
    if (responseData) {
      if (responseData.code === 0) {
        result.data = {
          callRequest: responseData.data,
        };
      } else {
        result.error = responseData.message;
      }
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
}
