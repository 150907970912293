import React from "react";
import { useTwilioUserMediaContext } from "../../call-sdk/context/TwilioUserMediaContext";
import VideoView from "./VideoView";
import { SelfViewContainer, VideoViewContainer } from "./VideoContainerStyles";

const SelfView = () => {
  const { isMediaPermissionGranted } = useTwilioUserMediaContext();

  const renderView = () => {
    if (isMediaPermissionGranted) {
      return (
        <VideoViewContainer>
          <VideoView />
        </VideoViewContainer>
      );
    }

    return null;
  };

  return <SelfViewContainer>{renderView()}</SelfViewContainer>;
};

export default SelfView;
