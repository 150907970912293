import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const onError = (
      message: string,
      source: string,
      lineno: number,
      colno: number,
      error: Error
    ) => {
      console.error("Error caught by ErrorBoundary:", error);

      // Capture the error and send it to Sentry
      Sentry.captureException(error);
      // Navigate to the /qr-error route
      navigate("/qr-error");
    };

    const eventListener: EventListener = (event) => {
      const errorEvent = event as ErrorEvent;
      onError(
        errorEvent.message,
        errorEvent.filename,
        errorEvent.lineno,
        errorEvent.colno,
        errorEvent.error as Error
      );
    };

    window.addEventListener("error", eventListener);

    return () => {
      window.removeEventListener("error", eventListener);
    };
  }, [navigate]);

  return <>{children}</>;
};

export default ErrorBoundary;
