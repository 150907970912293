import LocalStorage from "../call-sdk/lib/local-storage";
import { useCall } from "src/call-sdk/context/CallContext";
import { useSelfViewContext } from "src/call-sdk/context/SelfViewContext/SelfViewContext";
import { useTwilioUserMediaContext } from "src/call-sdk/context/TwilioUserMediaContext";
import { useRoomContext } from "src/call-sdk/context/RoomContext";
import { authlogout } from "src/services/qr-auth.service";
import StorageContants from "../config/storage.constants";

export default function useLogout() {
  const call = useCall();
  const { destroyMedia } = useTwilioUserMediaContext();
  const { destroy: destroySelfView } = useSelfViewContext();
  const { clearRoomContext } = useRoomContext();

  return async (calledOnLogout: boolean = true) => {
    const deviceId = LocalStorage.get(StorageContants.UNIQUE_DEVICE_ID) || "";
    let logoutSuccess = false;

    try {
      if (calledOnLogout) {
        const data = await authlogout({ deviceId });
        if (data) {
          destroySelfView();
          destroyMedia();
          clearRoomContext();
          call.destroy();
          LocalStorage.removeList(Object.values(StorageContants));
          // Set the flag to indicate successful logout
          logoutSuccess = true;
        }
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }

    return logoutSuccess;
  };
}
