const StorageContants = {
  ACCESS_TOKEN: "token", //initial auth token when qrCode_id is verified
  UNIQUE_DEVICE_ID: "UNIQUE_DEVICE_ID",
  TURN_OFF_SELFVIEW: "TURN_OFF_SELFVIEW",
  QR_ID: "QR_ID",
  REQUEST_ID: "REQUEST_ID",
  AUTH_API_TOKEN: "AUTH_API_TOKEN",
};

export default StorageContants;
