import styled from "styled-components";
import { Typography } from "@mui/material";


export const Container = styled.div`
  display: flex;
  background-color:white;
  flex-direction: column;
  max-height: 100vh;
  padding: 0.8rem 2rem;
  max-width: 680px;
  justify-self:center;
  align-self:center;
  margin:auto;
  .make-call-btn {
    width: 100%;
    font-size:21px;
    text-transform: none;
    font-weight: 600;
    line-height: 118%;
    height:7.5vh;
    @media (min-width: 550px) {
      width:50%;
    };
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; 
  margin-top: 10px;
  img
  {
    width:26vw;
    height:8vh;
    object-fit: contain;
    object-position: right center;
    @media (min-width: 550px) {
      width:20vw;
    };
  }
`;

export const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 1.5vh;
  width: 100%;
  @media (max-height: 568px) {
    img{
      height:20.2vh;
      margin-top: 1vh;
      margin-bottom: 1vh;
      object-fit: contain;
      object-position: center center;
    };
  
  };
  
`;

export const InformationContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

export const Header = styled(Typography)`
  display: flex;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 7.15vw;
  font-weight: 700;
  line-height: 118%;
  margin-bottom:10px;
  @media (min-width: 550px) {
    font-size: 27px;
  }  
`;

export const Content = styled(Typography)`
  display: flex;
  justify-content: center;
  font-size: 4.5vw;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  margin-top: 1.1vh;
  font-feature-settings: 'clig' off, 'liga' off;
  color: var(--black-100, #111);
  @media (min-width: 550px) {
    font-size: 2.5vw;

  }
  .words{
    text-decoration-line: underline;
  };
  p{
    padding:0px;
    margin:0px;
  };

`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  margin-top: 4.2vh;
  .terms 
  {
    color: var(--black-100, #111);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 16px;
    @media (min-width: 550px) {
      font-size: 12px;
    }  

  };
  .link{
    color: var(--info-default, #1884EB);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-decoration: none;
    @media (min-width: 550px) {
      font-size: 12px;
    }  

  }
`;

export const ContentArea = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-items: flex-start;
gap: 16px;
width:100%;

`;

export const ContentItemContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
gap: 12px;
align-self: stretch;
span{
    color: var(--black-100, #111);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    @media (max-height: 650px) {
      font-size: 17px;
      line-height: 120%;
    }  
  };
img
{
  height: 4.5vh;
  width:4.5vh;
  max-width:30px;
  max-height:30px;
  @media (max-height: 650px) {
    height: 4vh;
    width:4vh;
    
  }  
};

`;
export const Header2 = styled(Typography)`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 7.15vw;
  font-weight: 700;
  line-height: 118%;
  @media (min-width: 550px) {
    font-size: 27px;
  }  
`;