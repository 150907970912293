import React from "react";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCall } from "../../call-sdk/context/CallContext";
import Config from "../../config";

// Define an interface for the styled component props
interface ContainerProps {
  isSmallScreenLaptop: boolean;
}

const HeaderContainer = styled("div")({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "25px",
});

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "white",
}));

const Content = styled("div")<ContainerProps>(({ isSmallScreenLaptop }) => ({
  textAlign: isSmallScreenLaptop ? "center" : "left",
  fontFamily: "Source Sans Pro, sans-serif",
  position: "fixed",
  // top: "40vh",
}));

const Heading = styled("h2")<ContainerProps>(({ isSmallScreenLaptop }) => ({
  color: "black",
  fontSize: isSmallScreenLaptop ? "24px" : "32px",
  marginBottom: "10px",
  padding: "1rem",
}));

const MobileError = () => {
  const isSmallScreenLaptop = useMediaQuery("(max-width: 1024px)");
  const { error } = useCall();

  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  return (
    <Container>
      <HeaderContainer id="header-container">
        <img
          src={regionConfig.appLogoImageSrc}
          alt="Convo Logo"
          id="logo-image"
          width="100%"
          height={regionConfig.appLogoImageHeight}
        />
      </HeaderContainer>
      <Content isSmallScreenLaptop={isSmallScreenLaptop}>
        <Heading
          isSmallScreenLaptop={isSmallScreenLaptop}
          id="error-heading"
          tabIndex={0}
        >
          {error ??
            "Oops! Please try again later or feel free to reach out to your business partner for assistance."}
        </Heading>
      </Content>
    </Container>
  );
};

export default MobileError;
