import { createContext } from "react";
import { CallContextValues, AuthLoginState, Business } from "./types";

const CallContext = createContext<CallContextValues>({
  init: () => {},
  destroy: () => {},
  getAuthenticationToken: () => {},
  authLoginResponse: {} as AuthLoginState | null,
  refreshedAccessToken: null,
  business: {} as Business | null,
  setBusinessDetails: () => {},
  isBusinessFetched: false,
  getVideoInterpreter: (phoneNumber: string) => {},
  makeCall: () => {},
  hangup: () => {},
  disconnect: () => {},
  setVco: () => {},
  isCameraTurnedOffBeforeCall: false,
  clearError: () => {},
  requestId: null,
  requestType: "VRI",
  requestStatus: null,
  roomName: null,
  region: null,
  twilioRoomId: null,
  twilioVideoToken: null,
  twilioVoiceToken: null,
  toPhoneNumber: null,
  isConnecting: false,
  connected: false,
  isIncomingCall: false,
  isOutgoingCall: false,
  interpreterName: null,
  interpreterNRPCDNumber: null,
  interpreterId: null,
  callerName: null,
  callerNumber: null,
  vco: true,
  ringTimeout: 0,
  providerName: null,
  callDuration: 0,
  calleeName: null,
  calleeNumber: null,
  isJoining: false,
  position: null,
  transferInterpreterName: null,
  transferInterpreterId: null,
  error: null,
  reject: () => {},
  isCallRejected: false,
  join: () => {},
  callEnded: null,
  callRejectedStatus: () => {},
  apiErrorFlag: false,
  isWebTokenExpired: false,
  webTokenHandler: (tokenflag: boolean) => {},
});

export default CallContext;
