import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import useLogout from "src/hooks/useLogout";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import useReactPath from "src/hooks/useReactPath";
import { datadogRum } from "@datadog/browser-rum";
import LocalStorage from "../../call-sdk/lib/local-storage";
import StorageContants from "../../config/storage.constants";
import Messages from "../../config/messages.constants";
import Config from "../../config";

const HeaderContainer = styled("div")({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "25px",
});

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "white",
}));

const Text = styled(Typography)`
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
`;

const FeedBack = () => {
  const logoutUser = useLogout();
  const navigate = useNavigate();
  const currentPath = useReactPath();

  const statusMessage = React.useRef(Messages.FEEDBACK_MESSAGE);
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  useEffect(() => {
    const performLogout = async () => {
      const loggedOut = await logoutUser();
      if (loggedOut) {
        datadogRum.addAction("Logout successfull navigating to hubspot page");
        if (process.env.REACT_APP_HUBSPOT_FEEDBACK_URL) {
          datadogRum.clearUser();
          datadogRum.clearGlobalContext();
          window.location.replace(process.env.REACT_APP_HUBSPOT_FEEDBACK_URL);
          statusMessage.current = Messages.FEEDBACK_END_MESSAGE;
        }
      } else {
        navigate({ pathname: "/qr-error" }, { replace: true });
      }
    };

    const localData = LocalStorage.get(StorageContants.AUTH_API_TOKEN);
    if (localData) {
      performLogout();
    } else {
      statusMessage.current = Messages.FEEDBACK_END_MESSAGE;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const disableBackButton = () => {
      if (currentPath !== "/qr-feedback") {
        navigate({ pathname: "/qr-error" }, { replace: true });
      }
    };

    window.addEventListener("popstate", disableBackButton);

    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, [currentPath, navigate]);

  return (
    <ErrorBoundary>
      <Container>
        <HeaderContainer id="header-container">
          <img
            src={regionConfig.appLogoImageSrc}
            alt="Convo Logo"
            id="logo-image"
            width="100%"
            height={regionConfig.appLogoImageHeight}
          />
        </HeaderContainer>
        <Text>
          {statusMessage.current === Messages.FEEDBACK_MESSAGE && (
            <CircularProgress color="success" />
          )}
        </Text>
        <Text color="black">{statusMessage.current}</Text>
      </Container>
    </ErrorBoundary>
  );
};

export default FeedBack;
