import { LocalVideoTrack } from "twilio-video";
import { useTwilioUserMediaContext } from "../call-sdk/context/TwilioUserMediaContext";

export type UseVideoTrackResult = LocalVideoTrack | undefined;

export default function useVideoTrack(): UseVideoTrackResult {
  const { videoTracks } = useTwilioUserMediaContext();

  return videoTracks.length > 0 ? videoTracks[0] : undefined;
}
