export default class LocalStorage {
  static prefix: string = "QR_CUSTOMER_WEB_APP";

  private static getKey(key: string) {
    return `${LocalStorage.prefix}_${key.toUpperCase()}`;
  }

  static get(key: string, defaultValue: any = null) {
    return localStorage.getItem(LocalStorage.getKey(key)) ?? defaultValue;
  }

  static set(key: string, value: any) {
    localStorage.setItem(LocalStorage.getKey(key), value);
  }

  static remove(key: string) {
    localStorage.removeItem(LocalStorage.getKey(key));
  }

  static removeList(keys: string[]) {
    keys.forEach(this.remove);
  }

  static clear() {
    localStorage.clear();
  }
}
