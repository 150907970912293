import { useEffect, useState } from "react";
import { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

type Track = LocalVideoTrack | RemoteVideoTrack | undefined;

export default function useVideoToggle(track: Track) {
  const [isVideoEnabled, setVideoEnabled] = useState(!!track?.isEnabled);

  useEffect(() => {
    if (track?.isEnabled) {
      setVideoEnabled(true);
    } else {
      setVideoEnabled(false);
    }

    const setEnabled = () => setVideoEnabled(true);
    const setDisabled = () => setVideoEnabled(false);

    track?.on("enabled", setEnabled);
    track?.on("disabled", setDisabled);

    return () => {
      track?.off("enabled", setEnabled);
      track?.off("disabled", setDisabled);
    };
  }, [track]);

  return { isVideoEnabled };
}
