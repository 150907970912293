import { AxiosError } from "axios";
import Messages from "../config/messages.constants";

interface ErrorResponse {
  message?: string;
  // Add other properties if needed based on your actual server response
}

/**
 * Get Error Message from Error object
 * @param {AxiosError} error the error object from the server
 */
export function getErrorMessage(error: AxiosError<ErrorResponse>) {
  let message;
  if (!navigator.onLine) {
    message = Messages.NO_INTERNET;
  } else if (error?.response?.data?.message) {
    message = error.response.data.message;
  } else {
    message = Messages.UNKNOW_ERROR;
  }
  return message;
}

export function getErrorStatus(error: AxiosError) {
  return error.response?.status;
}
