import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QrAuthRequest } from "src/types";
import { useCall } from "src/call-sdk/context/CallContext";
import generateDeviceId from "src/lib/generateDeviceId";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary"; // Import the ErrorBoundary component
import { datadogRum } from "@datadog/browser-rum";
import LocalStorage from "../call-sdk/lib/local-storage";
import StorageContants from "src/config/storage.constants";
import Config from "../config";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
  background-color: white;
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 25px;
`;

const SpinnerContainer = styled.div`
  flex: 1; /* Allow the spinner container to take all available vertical space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add some bottom margin to create space at the bottom */
`;

const Text = styled(Typography)`
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
`;

const Index = () => {
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  const { qr_code_id } = useParams();
  const isAuthQrFetchedRef = useRef(false);
  const {
    getAuthenticationToken,
    authLoginResponse,
    init,
    refreshedAccessToken,
    setBusinessDetails,
    isBusinessFetched,
    apiErrorFlag,
    business,
  } = useCall();
  const [businessId, setBusinessId] = useState<string | null>(null);
  const navigate = useNavigate();

  const startWebSocket = useCallback(
    (initialize: any) => {
      const deviceId = generateDeviceId();
      datadogRum.addAction("starting websocket");
      init({
        userId: initialize.userId,
        authToken: initialize.authToken,
        deviceId,
      });
    },
    [init]
  );

  useEffect(() => {
    if (qr_code_id && !isAuthQrFetchedRef.current) {
      LocalStorage.set(StorageContants.QR_ID, qr_code_id);
      localStorage.removeItem("hasReloaded");
      let authRequestBody: QrAuthRequest = {
        qrCodeId: qr_code_id,
      };
      getAuthenticationToken(authRequestBody);
      isAuthQrFetchedRef.current = true;
    }
  }, [getAuthenticationToken, qr_code_id]);

  useEffect(() => {
    if (authLoginResponse) {
      const { uuid, token, businessId } = authLoginResponse;
      const User = {
        id: uuid,
      };
      datadogRum.addAction("authentication successfull");
      datadogRum.setUser(User);
      let initialize = {
        userId: uuid,
        authToken: token,
      };
      startWebSocket(initialize);
      setBusinessId(businessId);
    }
  }, [authLoginResponse, startWebSocket]);

  useEffect(() => {
    if (refreshedAccessToken && businessId) {
      datadogRum.setGlobalContextProperty("businesss", business);
      setBusinessDetails(businessId);
    }
  }, [refreshedAccessToken, businessId, setBusinessDetails, business]);

  // Redirect to "/qr-business" when isBusinessFetched becomes true
  useEffect(() => {
    if (isBusinessFetched) {
      datadogRum.addAction("navigating to landing page ( /qr-business )");
      navigate({ pathname: "/qr-business" }, { replace: true });
    }
  }, [isBusinessFetched, navigate]);

  useEffect(() => {
    if (apiErrorFlag || !qr_code_id) {
      datadogRum.addAction("navigating to error page ( /qr-error )");
      navigate({ pathname: "/qr-error" }, { replace: true });
    }
  }, [apiErrorFlag, navigate, qr_code_id]);

  return (
    <ErrorBoundary>
      {" "}
      {/* Wrap the content with ErrorBoundary */}
      <Container>
        <LogoContainer>
          <img
            src={regionConfig.appLogoImageSrc}
            alt="Logo"
            width="100%"
            height={regionConfig.appLogoImageHeight}
          />
        </LogoContainer>

        <SpinnerContainer>
          <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
            <CircularProgress color="success" />
          </Stack>
          <Text>Verifying...</Text>
        </SpinnerContainer>
      </Container>
    </ErrorBoundary>
  );
};

export default Index;
