import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Illutration from "../../assets/Illustration/qr-illustration.png";
import video_landing from "../../assets/Room/video_landing.svg";
import audio from "../../assets/Room/audio.svg";
import bluetooth from "../../assets/Room/bluetooth.svg";
import Config from "../../config";

import { useCall } from "../../call-sdk/context/CallContext";
import { Typography } from "@mui/material";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import {
  Container,
  LogoContainer,
  ButtonContainer,
  IllustrationContainer,
  ContentArea,
  ContentItemContainer,
  Header2,
} from "./LandingPageStyles";
// min-height: 100vh;
import ErrorBoundary from "../ErrorBoundary";
import { datadogRum } from "@datadog/browser-rum";
import LocalStorage from "src/call-sdk/lib/local-storage";

function LandingPage() {
  const { business, isCallRejected, callRejectedStatus, error, clearError } =
    useCall();
  const navigate = useNavigate();

  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  const openPermissionPage = () => {
    LocalStorage.remove("hasReloaded");
    if (isCallRejected) {
      callRejectedStatus();
    }
    datadogRum.addAction(
      "user clicked on start , navigating to permission page ( /qr-permission )"
    );
    navigate({ pathname: "/qr-permission" }, { replace: true });
  };
  useEffect(() => {
    changeAddressBarColor();
  }, []);
  const changeAddressBarColor = () => {
    let metas = document.head.getElementsByTagName("meta");
    metas[2].content = "#ffffff";
    document.body.style.backgroundColor = "#ffffff";
  };

  useEffect(() => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  return (
    <ErrorBoundary>
      <Container>
        <LogoContainer>
          <LazyLoadImage
            src={regionConfig.appLogoImageSrc}
            alt="Convo Logo"
            effect="blur"
          />

          <LazyLoadImage
            src={business?.photoURL ?? ""}
            alt="Business Logo"
            effect="blur"
          />
        </LogoContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "20px",
          }}
        >
          <IllustrationContainer>
            <LazyLoadImage
              src={Illutration}
              alt="Convo Illustration"
              effect="blur"
              style={{
                display: "block",
                objectFit: "contain",
                width: "100%",
                maxHeight: "24.24vh",
              }}
            />
          </IllustrationContainer>

          <ContentArea>
            <Header2>Get an interpreter</Header2>

            <ContentItemContainer>
              <LazyLoadImage
                src={video_landing}
                alt="Convo video"
                effect="blur"
              />
              <span>Grant access to your camera and microphone.</span>
            </ContentItemContainer>

            <ContentItemContainer>
              <LazyLoadImage src={audio} alt="Convo video" effect="blur" />
              <span>Increase your phone’s speaker volume.</span>
            </ContentItemContainer>

            <ContentItemContainer>
              <LazyLoadImage src={bluetooth} alt="Convo video" effect="blur" />
              <span>Disconnect Bluetooth for hearing aids or implants.</span>
            </ContentItemContainer>
          </ContentArea>
        </div>

        <ButtonContainer>
          <Typography variant="subtitle2" gutterBottom className="terms">
            By starting, you agree to&nbsp;
            {regionConfig.companyName}'s
            {regionConfig.region === "UK" ? <br /> : <>&nbsp;</>}
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_LEGAL_URL}
              style={{ color: `rgba(24, 132, 235, 1)` }}
            >
              Terms and Conditions
            </a>
            .
          </Typography>

          <Button
            className="make-call-btn"
            size="large"
            fullWidth
            onClick={openPermissionPage}
            style={{
              backgroundColor: "#00AA55",
              border: "none",
              borderRadius: "100px",
            }}
          >
            Start
          </Button>
        </ButtonContainer>
      </Container>
    </ErrorBoundary>
  );
}

export default LandingPage;
