import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { Business, APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";

export interface GetBusinessByIdResult extends APIResult {
  data?: {
    businessList: Business[];
  };
}

export interface GetBusinessByIdResponse extends APIResponse {
  data: {
    business: Business[];
  };
}
export const getBusinessListById = async (
  businessId: string
): Promise<GetBusinessByIdResult> => {
  const result = {} as GetBusinessByIdResult;
  try {
    const { data: responseData } = await axios.post<GetBusinessByIdResponse>(
      END_POINT.GET_BUSINESS_BY_ID,
      {
        businessId,
      }
    );
    result.data = {
      businessList: responseData.data.business,
    };
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
};
