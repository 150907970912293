import React, { useCallback, useEffect, useState } from "react";
import VideoContainerView from "../selfView/VideoContainerView";
import Button from "src/common/Button/Button";
import { useTwilioUserMediaContext } from "../../call-sdk/context/TwilioUserMediaContext";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import { datadogRum } from "@datadog/browser-rum";
import HelpScreenDialog from "./HelpScreen/HelpScreenDialog";
import { useCall } from "src/call-sdk/context/CallContext";
import {
  PermisionContainer,
  AccessContainer,
  ButtonContainer,
  HelpContainer,
} from "./PermissionStyles";
import Typography from "@mui/material/Typography";

const Permission = () => {
  const {
    requestMediaPermission,
    reRequestMediaPermission,
    audioDeviceId,
    videoDeviceId,
    isMediaPermissionGranted,
    isMediaPermissionDenied,
    isMediaPermissionDeniedByUser,
    destroyMedia,
  } = useTwilioUserMediaContext();
  const navigate = useNavigate();
  const [helpClicked, setHelpClicked] = useState(false);
  const call = useCall();

  const handleRequestPermission = useCallback(() => {
    if (!isMediaPermissionGranted) {
      requestMediaPermission({
        type: "ideal",
        audioDeviceId: audioDeviceId ?? "",
        videoDeviceId: videoDeviceId ?? "",
      });
    }
  }, [
    audioDeviceId,
    isMediaPermissionGranted,
    requestMediaPermission,
    videoDeviceId,
  ]);

  const handleReRequest = useCallback(() => {
    datadogRum.addAction("user clicked try again - reaccesing permissions ");
    reRequestMediaPermission({
      type: "ideal",
      audioDeviceId: audioDeviceId ?? "",
      videoDeviceId: videoDeviceId ?? "",
    });
  }, [audioDeviceId, reRequestMediaPermission, videoDeviceId]);

  // Function to check if the device is iOS
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };

  const handleIOSpermission = () => {
    destroyMedia();
    call.destroy();
    datadogRum.addAction(
      "user clicked on Try again in IOS - reaccesing permissions"
    );
    navigate({ pathname: `/qr-reconnecting` }, { replace: true });
  };

  useEffect(() => {
    changeAddressBarColor();
    if (isMediaPermissionGranted) {
      navigate({ pathname: "/qr-connecting" }, { replace: true });
    } else if (isMediaPermissionDenied) {
      datadogRum.addAction("user denied permissions ");
    } else {
      handleRequestPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediaPermissionGranted, isMediaPermissionDenied, navigate]);

  const changeAddressBarColor = () => {
    let metas = document.head.getElementsByTagName("meta");
    metas[2].content = "#000000";
    document.body.style.backgroundColor = "#000000";
  };

  const handleHelpClose = () => {
    setHelpClicked(false);
  };

  const handleHelpOpen = () => {
    setHelpClicked(true);
  };

  return (
    <ErrorBoundary>
      <PermisionContainer>
        <VideoContainerView />
        {(!isMediaPermissionGranted || isMediaPermissionDenied) && (
          <AccessContainer>
            {isMediaPermissionDeniedByUser && (
              <div>
                <p className="oops">Oops!</p>
                <p className="instruction">
                  Please try again by allowing access to your camera and
                  microphone
                </p>
              </div>
            )}
            <ButtonContainer>
              {isMediaPermissionDeniedByUser && (
                <Button
                  onClick={() => {
                    if (isIOS()) {
                      handleIOSpermission();
                    } else {
                      handleReRequest();
                    }
                  }}
                  fullWidth
                >
                  Try Again
                </Button>
              )}
            </ButtonContainer>
            {isMediaPermissionDeniedByUser && (
              <HelpContainer onClick={handleHelpOpen}>
                <Typography style={{ color: "#1884EB", fontSize: "20px" }}>
                  Need Help?
                </Typography>
              </HelpContainer>
            )}
          </AccessContainer>
        )}
      </PermisionContainer>

      <HelpScreenDialog open={helpClicked} onClose={handleHelpClose} />
    </ErrorBoundary>
  );
};

export default Permission;
