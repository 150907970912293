export const ColorTheme = {
    primary: '#00aa55',
    default: '#1884eb',
    secondary: '#b9c0c7',
    success: '#2ab852',
    warning: '#ff8900',
    danger: '#df352c',
    info: '',
};

export const ButtonTheme = {
    active: {
        primary: '#00934a',
        default: '#1884eb',
        secondary: '#b9c0c7',
        success: '#2ab852',
        warning: '#ff8900',
        danger: '#df352c',
        info: '',
    },
    hover: {
        primary: '#26bc71',
        default: '#42a5f5',
        secondary: '#d8dde2',
        success: '#2fc95a',
        warning: '#ff9c00',
        danger: '#fb3e34',
        info: '',
    },
    focus: {
        primary: '#00934a',
        default: '#0071d4',
        secondary: '#646b74',
        success: '#1ba341',
        warning: '#f37800',
        danger: '#d4271d',
        info: '',
    },
    disabled: {
        primary: 'rgba(0, 170, 85, 0.3)',
        default: '#1884eb',
        secondary: '#111111',
        success: '#2ab852',
        warning: '#ff8900',
        danger: '#df352c',
        info: '',
    },
};
export const TextButtonTheme = {
    default: {
        active: '#1884eb',
        hover: '#42a5f5',
        focus: '#0071d4',
        disabled: 'rgba(24, 132, 235, 0.3)',
    },
    primary: {
        active: '#00aa55',
        hover: '#26bc71',
        focus: '#00934a',
        disabled: 'rgba(0, 170, 85, 0.3)',
    },
    secondary: {
        active: '#000000',
        hover: 'rgba(0, 0, 0, 0.6)',
        focus: '#111111',
        disabled: 'rgba(0, 0, 0, 0.3)',
    },
    danger: {
        active: '#df352c',
        hover: '#fb3e34',
        focus: '#d4271d',
        disabled: '#df352c',
    },
    success: {
        active: '#2ab852',
        hover: '#2ab852',
        focus: '#2ab852',
        disabled: '#2ab852',
    },
    warning: {
        active: '#ff8900',
        hover: '#ff8900',
        focus: '#ff8900',
        disabled: '#ff8900',
    },
    info: {
        active: '#df352c',
        hover: '#df352c',
        focus: '#df352c',
        disabled: '#df352c',
    },
};
