import Bowser from "bowser";
import { RegisterDeviceOptions, UUID } from "../../types";

const browser = Bowser.parse(window.navigator.userAgent);

export interface WebsocketRegisterDeviceOptions {
  userId: UUID;
  deviceId: UUID;
}

export const getRegisterDeviceOptionsForWebsocket = ({
  userId,
  deviceId,
}: WebsocketRegisterDeviceOptions): RegisterDeviceOptions => {
  return {
    appversion: process.env.REACT_APP_VERSION ?? "",
    description: browser.browser.name ?? "",
    deviceid: deviceId,
    devicemake: browser.os.name ?? "",
    deviceos: browser.os.version ?? "",
    type: "Desktop",
    userId,
    appname: "userapp",
  };
};
