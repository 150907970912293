import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { UUID, APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";

export interface RejectCallResult extends APIResult {
  data?: {
    message: string;
  };
}

export interface RejectCallResponse extends APIResponse {
  data: null;
}

export interface RejectCallOptions {
  requestId: UUID;
  /**
   * Logged in user id
   */
  participantId: UUID;
}

export default async function rejectCall(options: RejectCallOptions) {
  const result: RejectCallResult = {};
  try {
    const { data: responseData } = await axios.post<RejectCallResponse>(
      END_POINT.QR_REJECT_CALL,
      options
    );
    if (responseData) {
      if (responseData.code === 0) {
        result.data = {
          message: responseData.message,
        };
      } else {
        result.error = responseData.message;
      }
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
}
