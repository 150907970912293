import React, { useRef, useEffect, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";
import {
  VIDEO_HEIGHT_FACTOR,
  VIDEO_WIDTH_FACTOR,
} from "../../config/video.constants";

import { StyledVideoInput } from "./VideoInputStyles";

export interface VideoInputProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  mediaStream?: MediaStream;
  resize?: boolean;
  videoRef?: React.RefObject<HTMLVideoElement>;
}

const VideoInput: React.FC<VideoInputProps> = ({
  mediaStream,
  videoRef: _videoRef = null,
  resize = false,
  ...restProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(_videoRef?.current || null);

  const calculateVideoDimension = (
    containerWidth: number,
    containerHeight: number
  ) => {
    let computedWidth = containerWidth;
    let computedHeight = containerWidth * VIDEO_HEIGHT_FACTOR;

    if (containerHeight < computedHeight) {
      computedHeight = containerHeight;
      computedWidth = computedHeight * VIDEO_WIDTH_FACTOR;
    }
    return {
      width: computedWidth,
      height: computedHeight,
    };
  };

  useEffect(() => {
    const videoEl = videoRef.current;
    if (videoEl) {
      videoEl.volume = 0;
      videoEl.muted = true;
      if (mediaStream) {
        videoEl.srcObject = mediaStream;
      }

      const handlePlayClick = () => {
        videoEl.play().catch((error) => {
          console.error("Autoplay error:", error);
        });
      };

      videoEl.addEventListener("click", handlePlayClick);
    }

    return () => {
      if (videoEl) {
        videoEl.srcObject = null;
      }
    };
  }, [mediaStream, videoRef]);

  const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      const { width, height } = entry.contentRect;
      const videoDimension = calculateVideoDimension(width, height);
      const videoEl = videoRef.current;
      if (videoEl) {
        videoEl.width = videoDimension.width;
        videoEl.height = videoDimension.height;
      }
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current && resize) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current && resize) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [handleResize, resize]);

  return (
    <StyledVideoInput>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        onContextMenu={(event) => event.preventDefault()}
        {...restProps}
      />
    </StyledVideoInput>
  );
};

export default VideoInput;
