/* eslint-disable class-methods-use-this */
import { UUID } from "../../types";
import { MessagingError } from "./error";
import WebSocketClient from "./websocket-client";
import * as Sentry from "@sentry/browser";

export interface MessageEventMap {
  token_registered: boolean;
  requesting_notification_permission: boolean;
  notification_enabled: boolean;
  message: any;
}

export type OnMessageCallback = <K extends keyof MessageEventMap>(
  event: K,
  message: MessageEventMap[K]
) => void;

export type OnErrorCallback = (error: string) => void;

export type MessagingType = "websocket";

export interface MessagingOptions {
  messagingType: MessagingType;
}

export interface InitOptions {
  userId: UUID;
  authToken: string;
  deviceId: UUID;
}

export default class Messaging {
  private userId: UUID | null = null;

  private authToken: string | null = null;

  private deviceId: UUID | null = null;

  private messagingType: MessagingType = "websocket";

  private websocketClient: WebSocketClient | null = null;

  private onMessageCallback: OnMessageCallback | null = null;

  private onErrorCallback: OnErrorCallback | null = null;

  isDeviceRegistered: boolean = false;

  constructor(options: MessagingOptions = { messagingType: "websocket" }) {
    this.messagingType = options.messagingType;
  }

  async init({ userId, deviceId, authToken }: InitOptions) {
    this.userId = userId;
    this.deviceId = deviceId;
    this.authToken = authToken;

    try {
      await this.initWebsocket();
    } catch (error: any) {
      Sentry.captureException(error);
      if (error instanceof MessagingError) {
        this.captureError(error.message);
      }
    }
  }

  async initWebsocket() {
    if (!this.userId) {
      throw new MessagingError(
        "User Id is required to establishing websocket connection"
      );
    }

    if (!this.deviceId) {
      throw new MessagingError(
        "Device Id is required to establishing websocket connection"
      );
    }

    if (!this.authToken) {
      throw new MessagingError(
        "Auth Token is required to establishing websocket connection"
      );
    }

    this.websocketClient = new WebSocketClient({
      url: `${process.env.REACT_APP_WEBSOCKET_URL}`,
      userId: this.userId,
      authToken: this.authToken,
      debug: console.log,
      deviceId: this.deviceId,
    });

    this.websocketClient.onRegisterDevice((isDeviceRegistered) => {
      this.isDeviceRegistered = isDeviceRegistered;
    });

    this.websocketClient.onMessage((data) =>
      this.onMessageCallback?.("message", data)
    );

    this.websocketClient.onError((error) => this.onErrorCallback?.(error));

    this.websocketClient.connect();
  }

  isNotificationSupported() {
    return "Notification" in window;
  }

  destroyWebsocket() {
    this.websocketClient?.destroy();
    this.websocketClient = null;
  }

  destroy() {
    this.destroyWebsocket();

    this.userId = null;
    this.deviceId = null;
    this.isDeviceRegistered = false;
    this.onMessageCallback = null;
    this.onErrorCallback = null;
  }

  onMessage(onMessageCallback: OnMessageCallback) {
    this.onMessageCallback = onMessageCallback;
  }

  onError(onErrorCallback: OnErrorCallback) {
    this.onErrorCallback = onErrorCallback;
  }

  private captureError(errorMsg: string) {
    this.onErrorCallback?.(errorMsg);
  }
}
