import { getContraints, GetContraintsOptions } from "./media-constraints";
import * as Sentry from "@sentry/browser";

export default class UserMediaError extends Error {
  name = "UserMediaError";

  constructor(public message: string) {
    super(message);
  }
}

export async function getUserMedia(
  constraints: MediaStreamConstraints
): Promise<MediaStream> {
  if (!constraints.audio && !constraints.video) {
    const errorMessage = "Please provide the options to getUserMedia";
    Sentry.captureEvent({
      message: errorMessage,
      level: "error",
    });
    throw new UserMediaError(errorMessage);
  }

  if ("mediaDevices" in navigator && navigator.mediaDevices.getUserMedia) {
    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error: any) {
      Sentry.captureException(error);
      const errorMessage = "Error while requesting media: " + error;

      throw new UserMediaError(errorMessage);
    }
  } else {
    const errorMessage = "getUserMedia is not supported on this browser.";
    Sentry.captureEvent({
      message: errorMessage,
      level: "error",
    });
    throw new UserMediaError(errorMessage);
  }
}

export interface StartMediaStreamOptions extends GetContraintsOptions {
  stream?: MediaStream;
}

export async function startMediaStream(
  options: StartMediaStreamOptions
): Promise<MediaStream> {
  const constraints = await getContraints(options);

  // Ensure at least one track is enabled
  if (!constraints.audio) {
    constraints.audio = true;
  }
  if (!constraints.video) {
    constraints.video = true;
  }

  try {
    return await getUserMedia(constraints);
  } catch (error: any) {
    Sentry.captureException(error);
    const errorMessage = "Error while starting media stream: " + error.message;

    throw new UserMediaError(errorMessage);
  }
}

export function stopMediaStream(mediaStream: MediaStream) {
  mediaStream.getTracks().forEach((track) => track.stop());
}

export function getAudioTrack(mediaStream: MediaStream) {
  const audioTracks = mediaStream.getAudioTracks();
  if (audioTracks.length > 0) {
    return audioTracks[0];
  }
  return null;
}

export function getVideoTrack(mediaStream: MediaStream) {
  const videoTracks = mediaStream.getVideoTracks();
  if (videoTracks.length > 0) {
    return videoTracks[0];
  }
  return null;
}

export function getDeviceId(track: MediaStreamTrack) {
  let deviceId = "";

  if (track.getSettings) {
    deviceId = track.getSettings().deviceId ?? "";
  } else if (track.getCapabilities) {
    deviceId = track.getCapabilities().deviceId ?? "";
  } else if (track.getConstraints) {
    deviceId = track.getConstraints().deviceId?.toString() ?? "";
  }

  return deviceId;
}

export function getVideoDeviceId(mediaStream: MediaStream) {
  const videoTrack = getVideoTrack(mediaStream);
  return videoTrack ? getDeviceId(videoTrack) : "";
}

export function getAudioDeviceId(mediaStream: MediaStream) {
  const audioTrack = getAudioTrack(mediaStream);
  return audioTrack ? getDeviceId(audioTrack) : "";
}

export function getVideoEnabled(mediaStream: MediaStream) {
  const videoTrack = getVideoTrack(mediaStream);
  return !!videoTrack?.enabled;
}

export function getAudioEnabled(mediaStream: MediaStream) {
  const audioTrack = getAudioTrack(mediaStream);
  return !!audioTrack?.enabled;
}

export function toggleVideoEnabled(mediaStream: MediaStream) {
  const videoTrack = getVideoTrack(mediaStream);
  if (videoTrack) {
    videoTrack.enabled = !videoTrack.enabled;
  }
}

export function toggleAudioEnabled(mediaStream: MediaStream) {
  const audioTrack = getAudioTrack(mediaStream);
  if (audioTrack) {
    audioTrack.enabled = !audioTrack.enabled;
  }
}
