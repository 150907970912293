import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react";
import {
  LocalVideoTrack,
  LocalAudioTrack,
  createLocalTracks,
  LocalTrack,
  CreateLocalTracksOptions,
} from "twilio-video";
import * as Media from "../../lib/media";
import * as Device from "../../lib/device";
import * as MediaConstraint from "../../lib/media-constraints";
import useDeviceChange from "../../../hooks/useDeviceChange";
import * as Sentry from "@sentry/react";
import { datadogRum } from "@datadog/browser-rum";

type MediaOptions = {
  videoDeviceId?: string;
  audioDeviceId?: string;
  type?: "ideal" | "exact";
  requestType?: "permission" | "stream";
};

const defaultMediaOptions: MediaOptions = {
  videoDeviceId: "",
  audioDeviceId: "",
  type: "ideal",
  requestType: "stream",
};

type RequestMediaPermission = (options?: MediaOptions) => Promise<void>;

type StartMedia = (options?: MediaOptions) => Promise<boolean>;

type StopMedia = () => void;

type Destroy = () => void;

type OnErrorCallback = (error: string) => void;

type HandleError = (onErrorCallback: OnErrorCallback) => void;

type SetSpeakerDeviceId = (deviceId: string) => void;

type VideoMetadata = {
  videoDeviceId: string | null;
};
type AudioMetadata = {
  audioDeviceId: string | null;
};
type SpeakerMetadata = {
  speakerDeviceId: string | null;
};
type BroadcastError = (message: string) => void;

type TwilioUserMediaState = {
  isMediaPermissionInitialized: boolean;
  isRequestingMediaPermission: boolean;
  isRequestingVideoPermission: boolean;
  isRequestingAudioPermission: boolean;
  isMediaPermissionGranted: boolean;
  isMediaPermissionDenied: boolean;
  isMediaPermissionDeniedByUser: boolean;
  isMediaStopped: boolean;
  mediaError: string | null;
  isVideoBlocked: boolean;
  isAudioBlocked: boolean;
  videoTracks: LocalVideoTrack[];
  audioTracks: LocalAudioTrack[];
  videoDeviceId: string | null;
  audioDeviceId: string | null;
  speakerDeviceId: string | null;
};

type TwilioUserMediaContextValues = TwilioUserMediaState & {
  devices: MediaDeviceInfo[];
  cameras: MediaDeviceInfo[];
  microphones: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
  startMedia: StartMedia;
  stopMedia: StopMedia;
  destroyMedia: Destroy;
  handleError: HandleError;
  setSpeakerDeviceId: SetSpeakerDeviceId;
  requestMediaPermission: RequestMediaPermission;
  reRequestMediaPermission: RequestMediaPermission;
  broadcastError: BroadcastError;
};

const defaultTwilioUserMediaState: TwilioUserMediaState = {
  isMediaPermissionInitialized: false,
  isRequestingMediaPermission: false,
  isRequestingVideoPermission: false,
  isRequestingAudioPermission: false,
  isMediaPermissionGranted: false,
  isMediaPermissionDenied: false,
  isMediaPermissionDeniedByUser: false,
  isMediaStopped: true,
  mediaError: null,
  isVideoBlocked: false,
  isAudioBlocked: false,
  videoTracks: [],
  audioTracks: [],
  videoDeviceId: Device.retrieveVideoDeviceId(),
  audioDeviceId: Device.retrieveAudioDeviceId(),
  speakerDeviceId: Device.retrieveSpeakerDeviceId(),
};

export const TwilioUserMediaContext =
  createContext<TwilioUserMediaContextValues>({
    ...defaultTwilioUserMediaState,
    devices: [],
    cameras: [],
    microphones: [],
    speakers: [],
    startMedia: () => Promise.resolve(false),
    stopMedia: () => {},
    destroyMedia: () => {},
    handleError: () => {},
    setSpeakerDeviceId: () => {},
    requestMediaPermission: async () => {},
    reRequestMediaPermission: async () => {},
    broadcastError: () => {},
  });

export const TwilioUserMediaConsumer = TwilioUserMediaContext.Consumer;

export const useTwilioUserMediaContext = () =>
  useContext(TwilioUserMediaContext);

export const stopLocalTracks = (
  tracks: Array<LocalVideoTrack | LocalAudioTrack>
) => {
  tracks.forEach((track) => track.stop());
};

export const TwilioUserMediaProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userMediaState, setTwilioUserMediaState] =
    useState<TwilioUserMediaState>(defaultTwilioUserMediaState);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const [errorCallbacks, setErrorCallbacks] = useState<OnErrorCallback[]>([]);

  const broadcastError: BroadcastError = useCallback(
    (message: string) => {
      errorCallbacks.forEach((errorCallback) => errorCallback(message));
    },
    [errorCallbacks]
  );

  const handleError = useCallback<HandleError>((onErrorCallback) => {
    setErrorCallbacks((callbacks) => [...callbacks, onErrorCallback]);
  }, []);

  const createVideoMetadata = (videoTrack: LocalVideoTrack): VideoMetadata => {
    const selectedVideoDeviceId = Media.getDeviceId(
      videoTrack.mediaStreamTrack
    );
    Device.storeVideoDeviceId(selectedVideoDeviceId);

    return {
      videoDeviceId: selectedVideoDeviceId,
    };
  };

  const createAudioMetadata = (audioTrack: LocalAudioTrack): AudioMetadata => {
    const selectedAudioDeviceId = Media.getDeviceId(
      audioTrack.mediaStreamTrack
    );
    Device.storeAudioDeviceId(selectedAudioDeviceId);
    return {
      audioDeviceId: selectedAudioDeviceId,
    };
  };

  const createSpeakerMetadata = (
    _devices: MediaDeviceInfo[]
  ): SpeakerMetadata => {
    let selectedSpeakerDeviceId = Device.retrieveSpeakerDeviceId();

    const speakers = Device.getSpeakers(_devices);

    if (!selectedSpeakerDeviceId && speakers.length > 0) {
      selectedSpeakerDeviceId = speakers[0].deviceId;
    }

    Device.storeSpeakerDeviceId(selectedSpeakerDeviceId);

    return {
      speakerDeviceId: selectedSpeakerDeviceId,
    };
  };

  // Get All available devices
  const getDevices = async () => {
    setDevices(await Device.getDevices());
  };

  const startMedia = useCallback<StartMedia>(
    async (options = defaultMediaOptions) => {
      const isRequestingVideoPermission =
        typeof options.videoDeviceId !== "undefined";
      const isRequestingAudioPermission =
        typeof options.audioDeviceId !== "undefined";

      setTwilioUserMediaState((state) => ({
        ...state,
        isRequestingMediaPermission: true,
        isRequestingVideoPermission,
        isRequestingAudioPermission,
        isMediaPermissionGranted: false,
        isMediaPermissionDenied: false,
      }));

      const { videoTracks: oldVideoTracks, audioTracks: oldAudioTracks } =
        userMediaState;

      let videoTracks: LocalVideoTrack[] = oldVideoTracks;
      let audioTracks: LocalAudioTrack[] = oldAudioTracks;

      if (isRequestingVideoPermission) {
        videoTracks = [];
        stopLocalTracks(oldVideoTracks);
        setTwilioUserMediaState((state) => ({
          ...state,
          videoTracks,
        }));
      }

      if (isRequestingAudioPermission) {
        audioTracks = [];
        stopLocalTracks(oldAudioTracks);
        setTwilioUserMediaState((state) => ({
          ...state,
          audioTracks,
        }));
      }

      try {
        let newDevices = await Device.getDevices();

        const tracksOptions = MediaConstraint.getContraints({
          ...options,
          devices: newDevices,
        });

        const tracks = await createLocalTracks(
          tracksOptions as CreateLocalTracksOptions
        );

        if (isRequestingVideoPermission) {
          videoTracks = tracks.filter(
            (track: LocalTrack) => track.kind === "video"
          ) as LocalVideoTrack[];
        }

        if (isRequestingAudioPermission) {
          audioTracks = tracks.filter(
            (track: LocalTrack) => track.kind === "audio"
          ) as LocalAudioTrack[];
        }

        // in safari, devices will be available only after initial media permission.
        newDevices = await Device.getDevices();

        const videoMetadata: VideoMetadata =
          videoTracks.length > 0
            ? createVideoMetadata(videoTracks[0])
            : { videoDeviceId: null };

        const audioMetadata: AudioMetadata =
          audioTracks.length > 0
            ? createAudioMetadata(audioTracks[0])
            : { audioDeviceId: null };

        const speakerMetadata: SpeakerMetadata =
          createSpeakerMetadata(newDevices);

        let isMediaStopped = false;

        setTwilioUserMediaState((state) => ({
          ...state,
          mediaStreamError: null,
          isMediaPermissionInitialized: true,
          isRequestingMediaPermission: false,
          isRequestingVideoPermission: false,
          isRequestingAudioPermission: false,
          isMediaPermissionGranted: true,
          isMediaPermissionDenied: false,
          isMediaStopped,
          isVideoBlocked: isRequestingVideoPermission
            ? false
            : state.isVideoBlocked,
          isAudioBlocked: isRequestingAudioPermission
            ? false
            : state.isAudioBlocked,
          videoTracks: isRequestingVideoPermission
            ? videoTracks
            : state.videoTracks,
          audioTracks: isRequestingAudioPermission
            ? audioTracks
            : state.audioTracks,
          ...videoMetadata,
          ...audioMetadata,
          ...speakerMetadata,
        }));

        // update device list
        setDevices(newDevices);
        datadogRum.addAction("Permission Granted | Media Started");
        return true;
      } catch (error: any) {
        Sentry.captureException(error);
        setTwilioUserMediaState((state) => ({
          ...state,
          mediaError: error,
          isMediaPermissionInitialized: true,
          isRequestingMediaPermission: false,
          isRequestingVideoPermission: false,
          isRequestingAudioPermission: false,
          isMediaPermissionGranted: false,
          isMediaPermissionDenied: true,
          isMediaStopped: false,
          isVideoBlocked: isRequestingVideoPermission,
          isAudioBlocked: isRequestingAudioPermission,
        }));
        broadcastError(error.message);
        return false;
      }
    },
    [broadcastError, userMediaState]
  );

  const requestMediaPermission = useCallback<RequestMediaPermission>(
    async (options = defaultMediaOptions) => {
      const flag = await startMedia({ ...options, requestType: "permission" });
      if (!flag) {
        setTwilioUserMediaState((state) => ({
          ...state,
          isMediaPermissionDeniedByUser: true,
        }));
      }
    },
    [startMedia]
  );

  const reRequestMediaPermission = useCallback<RequestMediaPermission>(
    async (options = defaultMediaOptions) => {
      if (userMediaState.isMediaPermissionDeniedByUser) {
        // Re-request media permissions
        try {
          await startMedia({ ...options, requestType: "permission" });
        } catch (error) {
          // Permissions still denied, update state accordingly
          setTwilioUserMediaState((state) => ({
            ...state,
            isMediaPermissionDeniedByUser: true,
          }));
        }
      }
    },
    [startMedia, userMediaState.isMediaPermissionDeniedByUser]
  );

  const stopMedia = useCallback(() => {
    const { audioTracks, videoTracks } = userMediaState;

    stopLocalTracks(videoTracks);
    stopLocalTracks(audioTracks);

    setTwilioUserMediaState((state) => ({
      ...state,
      isMediaStopped: true,
      videoTracks: [],
      audioTracks: [],
    }));
  }, [userMediaState]);

  const destroyMedia = useCallback(() => {
    stopMedia(); // Stop all tracks first
    Device.removeDeviceIds();
    setTwilioUserMediaState((state) => ({
      ...state,
      ...defaultTwilioUserMediaState,
    }));
  }, [stopMedia]);

  const setSpeakerDeviceId = useCallback((deviceId: string) => {
    Device.storeSpeakerDeviceId(deviceId);
    setTwilioUserMediaState((state) => ({
      ...state,
      speakerDeviceId: deviceId,
    }));
  }, []);

  // detect camera removal/addition and start the available camera
  const checkCameraDetached = (_devices: MediaDeviceInfo[]) => {
    const { videoDeviceId, videoTracks } = userMediaState;
    const cameras = Device.getCameras(_devices);

    const isCameraDetached =
      videoTracks.length > 0 &&
      !cameras.some((camera) => camera.deviceId === videoDeviceId);

    if (isCameraDetached && cameras.length > 0) {
      startMedia({ videoDeviceId: cameras[0].deviceId, type: "exact" });
    }
  };

  // detect microphone removal/addition and start the available micrphone
  const checkMicrophoneDetached = (_devices: MediaDeviceInfo[]) => {
    const { audioDeviceId, audioTracks } = userMediaState;
    const microphones = Device.getMicrophones(_devices);

    const isMicrophoneDetached =
      audioTracks.length > 0 &&
      !microphones.some((microphone) => microphone.deviceId === audioDeviceId);

    if (isMicrophoneDetached && microphones.length > 0) {
      startMedia({ audioDeviceId: microphones[0].deviceId, type: "exact" });
    }
  };

  useDeviceChange(
    (_devices) => {
      checkCameraDetached(_devices);
      checkMicrophoneDetached(_devices);
      setDevices(_devices);
    },
    [userMediaState]
  );

  useEffect(() => {
    getDevices();
  }, []);

  // Use useMemo to prevent re-creating the context value object on every render
  const contextValue = useMemo(
    () => ({
      devices,
      cameras: Device.getCameras(devices),
      microphones: Device.getMicrophones(devices),
      speakers: Device.getSpeakers(devices),
      startMedia,
      stopMedia,
      destroyMedia,
      handleError,
      setSpeakerDeviceId,
      requestMediaPermission,
      reRequestMediaPermission,
      broadcastError,
      ...userMediaState,
    }),
    [
      broadcastError,
      destroyMedia,
      devices,
      handleError,
      requestMediaPermission,
      reRequestMediaPermission,
      setSpeakerDeviceId,
      startMedia,
      stopMedia,
      userMediaState,
    ]
  );

  return (
    <TwilioUserMediaContext.Provider value={contextValue}>
      {children}
    </TwilioUserMediaContext.Provider>
  );
};
