import axios from "axios";
import END_POINT from "../config/endpoints.constants";
import { ParticipantType } from "../types/participant.type";
import { APIResponse, APIResult } from "../types";
import { getErrorMessage } from "../lib/error-handling";

export interface GetParticipantTypeOptions {
  requestId: string | null;
  participantId: string;
}

export interface GetParticipantTypeResponse extends APIResponse {
  data: {
    participantType: ParticipantType;
    deviceType: string;
    deviceMake: string;
  };
}

export interface GetParticipantTypeResult extends APIResult {
  data?: {
    participantType: ParticipantType;
    deviceType: string;
    deviceMake: string;
  };
}

export async function getParticipantType(
  options: GetParticipantTypeOptions
): Promise<GetParticipantTypeResult> {
  const result: GetParticipantTypeResult = {};
  try {
    const { data: responseData } = await axios.post<GetParticipantTypeResponse>(
      END_POINT.QR_GET_PARTICIPANT_TYPE,
      options
    );
    if (responseData.data) {
      result.data = {
        participantType: responseData.data.participantType,
        deviceType: responseData.data.deviceType,
        deviceMake: responseData.data.deviceMake,
      };
    }
  } catch (error: any) {
    result.error = getErrorMessage(error);
  }
  return result;
}
