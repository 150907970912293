import React from "react";
import styled from "styled-components";
import TryAgain from "../../../../../assets/Help/Buttons/Button-TryAgain.svg";
import Config from "src/config";

const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Body 1 Regular */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const ImgDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const TextDiv = styled.div`
  display: flex;
  text-align: left;
`;

const ImgCo = styled.img`
  width: 248px;
  flex-shrink: 0;
`;

const ContentSpan = styled.span`
  padding-left: 4px;
`;

const BrowserHelpContent = () => {
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  return (
    <BrowserContainer>
      <>
        <TextDiv>
          <span>1.</span>
          <ContentSpan>
            Tap icon on left side of browser’s address bar.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo
            height="31.66px"
            src={regionConfig.addressBarImageSrc}
            alt="address-bar-img"
          />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>2.</span>
          <ContentSpan>Tap “Permissions”.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo
            height="168.319px"
            src={regionConfig.permissionSettingImageSrc}
            alt="permission-settting"
          />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>3.</span>
          <ContentSpan>
            Tap switches to allow access to Camera and Microphone.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo
            height="277.901px"
            src={regionConfig.permissionOffImageSrc}
            alt="permission-settting-off"
          />
        </ImgDiv>
        <br />

        <ImgDiv>
          <ImgCo
            height="277.901px"
            src={regionConfig.permissionOnImageSrc}
            alt="permission-settting-on"
          />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>4.</span>
          <ContentSpan>Close menu and then tap “Try Again”.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo src={TryAgain} alt="try-again-button" />
        </ImgDiv>
        <br />
      </>
    </BrowserContainer>
  );
};

export default BrowserHelpContent;
