import React from "react";
import styled from "styled-components";
import SettingsIcon from "../../../../../assets/Help/IOS/iOS-Chrome-SettingsIcon.png";
import SettingsChrome from "../../../../../assets/Help/IOS/iOS-Chrome-SettingsList.png";
import SettingMicCamOFF from "../../../../../assets/Help/IOS/iOS-Chrome-SettingsCamMicOff.png";
import SettingMicCamOn from "../../../../../assets/Help/IOS/iOS-Chrome-SettingsCamMicOn.png";
import TryAgain from "../../../../../assets/Help/Buttons/Button-TryAgain.svg";
import StartButton from "../../../../../assets/Help/Buttons/Button-Start.svg";
import Config from "src/config";

const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Body 1 Regular */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const TextDiv = styled.div`
  display: flex;
  text-align: left;
`;

const ImgDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgSettings = styled.img`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;

const ImgCo = styled.img`
  width: 249px;
  flex-shrink: 0;
`;

const ContentSpan = styled.span`
  padding-left: 4px;
`;

const ChromeContent = () => {
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  return (
    <BrowserContainer>
      <>
        <TextDiv>
          <span>1.</span>
          <ContentSpan>Open Settings on your iPhone.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgSettings src={SettingsIcon} alt="open-settings" />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>2.</span>
          <ContentSpan>Scroll down & choose Chrome from list.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo height="70.424px" src={SettingsChrome} alt="find-chrome" />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>3.</span>
          <ContentSpan>
            Tap switches to allow access to Camera and Microphone.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo
            height="91.058px"
            src={SettingMicCamOFF}
            alt="mic-cam-disable"
          />
        </ImgDiv>
        <br />
        <ImgDiv>
          <ImgCo height="91.058px" src={SettingMicCamOn} alt="mic-cam-enable" />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>4.</span>
          <ContentSpan>
            Close Settings; return to Chrome, then tap “Try Again” to restart
            process.
          </ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo src={TryAgain} alt="try-again" />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>5.</span>
          <ContentSpan>Tap “Start”.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo src={StartButton} alt="start" />
        </ImgDiv>
        <br />
      </>

      <>
        <TextDiv>
          <span>6.</span>
          <ContentSpan>Tap “Allow”.</ContentSpan>
        </TextDiv>
        <br />
        <ImgDiv>
          <ImgCo src={regionConfig.allowControlsImageSrc} alt="allow-tap" />
        </ImgDiv>
        <br />
      </>
    </BrowserContainer>
  );
};

export default ChromeContent;
