import LocalStorage from "./local-storage";

async function enumerateDevicesPolyfill(): Promise<MediaDeviceInfo[]> {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const polyfilledDevices = devices.map(
      (device) =>
        new PolyfilledMediaDeviceInfo(
          device.deviceId,
          device.kind,
          device.label
        )
    );
    return polyfilledDevices;
  } catch (error) {
    console.error("Error enumerating devices:", error);
    return [];
  }
}

class PolyfilledMediaDeviceInfo implements MediaDeviceInfo {
  constructor(
    public readonly deviceId: string,
    public readonly kind: MediaDeviceKind,
    public readonly label: string,
    public readonly groupId: string = ""
  ) {}

  toJSON() {
    return {
      deviceId: this.deviceId,
      kind: this.kind,
      label: this.label,
      groupId: this.groupId,
    };
  }
}

async function safeEnumerateDevices(): Promise<MediaDeviceInfo[]> {
  try {
    const devices = await enumerateDevicesPolyfill();

    return devices;
  } catch (error) {
    return [];
  }
}

export async function getDevices(): Promise<MediaDeviceInfo[]> {
  return safeEnumerateDevices();
}

export function isDeviceAvailable(
  kind: MediaDeviceKind,
  deviceId: string,
  devices: MediaDeviceInfo[]
): boolean {
  return devices.some(
    (device) => device.kind === kind && device.deviceId === deviceId
  );
}

export function isAudioDeviceAvailable(
  deviceId: string,
  devices: MediaDeviceInfo[]
): boolean {
  return isDeviceAvailable("audioinput", deviceId, devices);
}

export function isVideoDeviceAvailable(
  deviceId: string,
  devices: MediaDeviceInfo[]
): boolean {
  return isDeviceAvailable("videoinput", deviceId, devices);
}

export const VIDEO_DEVICE_ID = "VIDEO_DEVICE_ID";

export function storeVideoDeviceId(deviceId: string) {
  LocalStorage.set(VIDEO_DEVICE_ID, deviceId);
}

export function retrieveVideoDeviceId() {
  return LocalStorage.get(VIDEO_DEVICE_ID) || "";
}

export function removeVideoDeviceId() {
  LocalStorage.remove(VIDEO_DEVICE_ID);
}

export const AUDIO_DEVICE_ID = "AUDIO_DEVICE_ID";

export function storeAudioDeviceId(deviceId: string) {
  LocalStorage.set(AUDIO_DEVICE_ID, deviceId);
}

export function retrieveAudioDeviceId() {
  return LocalStorage.get(AUDIO_DEVICE_ID) || "";
}

export function removeAudioDeviceId() {
  LocalStorage.remove(AUDIO_DEVICE_ID);
}

export const SPEAKER_DEVICE_ID = "SPEAKER_DEVICE_ID";

export function storeSpeakerDeviceId(deviceId: string) {
  LocalStorage.set(SPEAKER_DEVICE_ID, deviceId);
}

export function retrieveSpeakerDeviceId() {
  return LocalStorage.get(SPEAKER_DEVICE_ID) || "";
}

export function removeSpeakerDeviceId() {
  LocalStorage.remove(SPEAKER_DEVICE_ID);
}

export function removeDeviceIds() {
  LocalStorage.removeList([
    VIDEO_DEVICE_ID,
    AUDIO_DEVICE_ID,
    SPEAKER_DEVICE_ID,
  ]);
}

export const getDevicesByKind = (
  devices: MediaDeviceInfo[],
  kind: MediaDeviceKind
) => {
  return devices.filter((device) => device.kind === kind);
};

export const getCameras = (devices: MediaDeviceInfo[]) => {
  return getDevicesByKind(devices, "videoinput");
};

export const getMicrophones = (devices: MediaDeviceInfo[]) => {
  return getDevicesByKind(devices, "audioinput");
};

export const getSpeakers = (devices: MediaDeviceInfo[]) => {
  return getDevicesByKind(devices, "audiooutput");
};
