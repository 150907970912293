const END_POINT = {
  QR_AUTH_LOGIN: `${process.env.REACT_APP_API_URL}/auth/authenticateQRUser`,
  GET_BUSINESS_BY_ID: `${process.env.REACT_APP_API_URL}/getBusinessById`,
  QR_MAKE_CALL: `${process.env.REACT_APP_API_URL}/makeCall`,
  QR_MUTE_USER_VOICE_CONFERENCE: `${process.env.REACT_APP_API_URL}/HOHUserMuteDone`,
  QR_UNMUTE_USER_VOICE_CONFERENCE: `${process.env.REACT_APP_API_URL}/HOHUserUnMuteDone`,
  QR_GET_PARTICIPANT_TYPE: `${process.env.REACT_APP_API_URL}/getParticipantType`,
  QR_REJECT_CALL: `${process.env.REACT_APP_API_URL}/rejectCall`,
  QR_GET_REQUEST_BY_ID: `${process.env.REACT_APP_API_URL}/getRequestById`,
  QR_HANGUP_CALL: `${process.env.REACT_APP_API_URL}/endVideoRoom`,
  QR_LOGOUT: `${process.env.REACT_APP_API_URL}/logout`,
};

export default END_POINT;
