import { useCallback, useEffect } from "react";
import { getDevices } from "../call-sdk/lib/device";
import * as Sentry from "@sentry/browser";

type DeviceChangeCallbackFn = (devices: MediaDeviceInfo[]) => void;

function useDeviceChange(
  callbackFn: DeviceChangeCallbackFn,
  dependencies: Array<any>
) {
  const handleDeviceChange = useCallback(() => {
    getDevices()
      .then((newDevices) => {
        callbackFn(newDevices);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error handling device change:", error);
      });
  }, [callbackFn]);

  useEffect(() => {
    const addDeviceChangeListener = () => {
      if (
        navigator.mediaDevices &&
        "addEventListener" in navigator.mediaDevices
      ) {
        navigator.mediaDevices.addEventListener(
          "devicechange",
          handleDeviceChange
        );
      }
    };

    const removeDeviceChangeListener = () => {
      if (
        navigator.mediaDevices &&
        "removeEventListener" in navigator.mediaDevices
      ) {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          handleDeviceChange
        );
      }
    };

    addDeviceChangeListener();

    return () => {
      removeDeviceChangeListener();
    };
  }, [handleDeviceChange]);
}

export default useDeviceChange;
