import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import IOSContent from "./IOS/IOSHelpContent";
import AndroidContent from "./Android/AndroidHelpContent";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#00934a",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#00934a",
    },
  },
});

interface HelpScreenDialogProps {
  open: boolean;
  onClose: () => void;
}

const HelpScreenDialog: React.FC<HelpScreenDialogProps> = ({
  open,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{ padding: "20px", overflowY: "auto" }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          backgroundColor: "#fff",
        }}
      >
        {/* Fixed Header */}
        <IconButton
          aria-label="Close"
          onClick={onClose}
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            margin: "5px",
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="primary"
          centered
          className={classes.tabs}
        >
          <Tab sx={{ textTransform: "none" }} label="iOS" />
          <Tab sx={{ textTransform: "none" }} label="Android" />
        </Tabs>
      </div>

      <DialogContent dividers>
        {/* Content */}
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          {activeTab === 0 && <IOSContent />}
          {activeTab === 1 && <AndroidContent />}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default HelpScreenDialog;
