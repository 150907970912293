import axios, { AxiosError } from "axios";
import React, { useEffect, useState, ReactNode, useCallback } from "react";
import END_POINT from "../../config/endpoints.constants";
import Messages from "../../config/messages.constants";
import StorageContants from "../../config/storage.constants";
import { useToast } from "../../context/ToastContext";
import LocalStorage from "src/call-sdk/lib/local-storage";
import { getErrorStatus } from "../../lib/error-handling";

const IGNORE_TOKEN_API_LIST = [END_POINT.QR_AUTH_LOGIN];

interface AxiosInterceptorsrProps {
  children: ReactNode;
}

const AxiosInterceptors: React.FC<AxiosInterceptorsrProps> = ({ children }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);

  const handleResponseError = useCallback(
    (error: AxiosError) => {
      const { config } = error;
      const url = config?.url ?? "";
      const status = getErrorStatus(error);

      if (!IGNORE_TOKEN_API_LIST.includes(url) && status === 401) {
        toast.error(Messages.TOKEN_EXPIRED);
      }

      return Promise.reject(error);
    },
    [toast]
  );

  useEffect(() => {
    const requestInterceptorId = axios.interceptors.request.use(
      async (config) => {
        const accessToken = LocalStorage.get(StorageContants.ACCESS_TOKEN);

        const url = config.url ?? "";
        if (accessToken && !IGNORE_TOKEN_API_LIST.includes(url)) {
          // eslint-disable-next-line no-param-reassign
          config.headers.authorization = `Bearer ${accessToken}`;
          // eslint-disable-next-line no-param-reassign
          config.headers["cache-control"] = `no-cache`;
        }

        return config;
      }
    );

    const responseInterceptorId = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      handleResponseError
    );

    setLoading(false);

    return () => {
      axios.interceptors.request.eject(requestInterceptorId);
      axios.interceptors.response.eject(responseInterceptorId);
    };
  }, [handleResponseError]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return <>{children}</>;
};

export default AxiosInterceptors;
