import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCall } from "src/call-sdk/context/CallContext";
import { useNavigate } from "react-router-dom";
import LocalStorage from "src/call-sdk/lib/local-storage";
import StorageConstants from "src/config/storage.constants";
import Messages from "src/config/messages.constants";
import Config from "../config";

// Define an interface for the styled component props
interface ContainerProps {
  isSmallScreenLaptop: boolean;
}

const HeaderContainer = styled("div")({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "25px",
});

const LogoImage = styled("img")({
  maxWidth: "100%",
  height: "25px",
});

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "white",
}));

const Content = styled("div")<ContainerProps>(({ isSmallScreenLaptop }) => ({
  textAlign: isSmallScreenLaptop ? "center" : "left",
  fontFamily: "Source Sans Pro, sans-serif",
  position: "fixed",
  // top: "40vh",
}));

const Heading = styled("h2")<ContainerProps>(({ isSmallScreenLaptop }) => ({
  color: "black",
  fontSize: isSmallScreenLaptop ? "24px" : "32px",
  marginBottom: "10px",
  padding: "1rem",
}));

const Reverifying = () => {
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  const isSmallScreenLaptop = useMediaQuery("(max-width: 1024px)");
  const { error, webTokenHandler, isWebTokenExpired } = useCall();

  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = LocalStorage.get("hasReloaded") === "true";

    if (!hasReloaded && isWebTokenExpired) {
      // Reload the page only once
      LocalStorage.set("hasReloaded", "true");
      window.location.reload();
      webTokenHandler(false);
    } else {
      setTimeout(() => {
        const qrId = LocalStorage.get(StorageConstants.QR_ID);
        navigate({ pathname: `/qr-verifying/${qrId}` }, { replace: true });
      }, 2000); // Adjust the delay as needed
    }
  }, [isWebTokenExpired, navigate, webTokenHandler]);

  return (
    <Container>
      <HeaderContainer id="header-container">
        <LogoImage
          src={regionConfig.appLogoImageSrc}
          alt="Convo Logo"
          id="logo-image"
        />
      </HeaderContainer>
      <Content isSmallScreenLaptop={isSmallScreenLaptop}>
        <Heading
          isSmallScreenLaptop={isSmallScreenLaptop}
          id="error-heading"
          tabIndex={0}
        >
          {error || Messages.TOKEN_ERROR}
        </Heading>
      </Content>
    </Container>
  );
};

export default Reverifying;
