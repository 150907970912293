import React from "react";
import VideoOff from "../../assets/Room/video-off.svg";
import VideoOffDenied from "../../assets/Room/video-denied.svg";

import { useTwilioUserMediaContext } from "../../call-sdk/context/TwilioUserMediaContext";
import {
  SelfViewContainer,
  NoVideoViewContainer,
} from "./VideoContainerStyles";

const SelfView = () => {
  const { isRequestingMediaPermission, isMediaPermissionDeniedByUser } =
    useTwilioUserMediaContext();

  const renderView = () => {
    if (isRequestingMediaPermission) {
      return (
        <NoVideoViewContainer>
          <img src={VideoOff} alt="No Camera" style={{ height: "8vh" }} />
        </NoVideoViewContainer>
      );
    }

    if (isMediaPermissionDeniedByUser) {
      return (
        <NoVideoViewContainer style={{ backgroundColor: "red" }}>
          <img src={VideoOffDenied} alt="No Camera" style={{ height: "8vh" }} />
        </NoVideoViewContainer>
      );
    }

    return null;
  };

  return <SelfViewContainer>{renderView()}</SelfViewContainer>;
};

export default SelfView;
