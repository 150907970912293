import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import Video, { Room } from "twilio-video";
import { useTwilioUserMediaContext } from "../TwilioUserMediaContext";
import * as Sentry from "@sentry/react";

type RoomContextValues = {
  room: Room | null;

  setRoom: React.Dispatch<React.SetStateAction<Room | null>>;
  connectToRoom: (
    twilioVideoToken: string,
    region: string,
    roomName: string
  ) => void;
  connecting: boolean;
  error: string | null;
  clearRoomContext: () => void;
};

const RoomContext = createContext<RoomContextValues>({
  room: null,
  setRoom: () => {},
  connectToRoom: () => {},
  connecting: false,
  error: null,
  clearRoomContext: () => {},
});

export const useRoomContext = () => useContext(RoomContext);

export const RoomProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [room, setRoom] = useState<Room | null>(null);
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { audioTracks, videoTracks } = useTwilioUserMediaContext();

  const clearRoomContext = useCallback(() => {
    setRoom(null);
    setConnecting(false);
    setError(null);
  }, []);

  const connectToRoom = useCallback(
    async (twilioVideoToken: string, region: string, roomName: string) => {
      setConnecting(true);
      setError(null);

      try {
        const twilioRoom = await Video.connect(twilioVideoToken, {
          region: region || "us2",
          name: roomName,
          tracks: [...videoTracks, ...audioTracks],
        });
        if (twilioRoom) {
          setRoom(twilioRoom);
        }
      } catch (error) {
        Sentry.captureException(error);
        setError(
          "Connectivity issues. Please close browser session and try starting again."
        );
      }
    },
    [audioTracks, videoTracks]
  );

  useEffect(() => {
    if (room?.state === "connected") {
      setConnecting(false);
    }
  }, [room?.state]);

  const contextValue = useMemo(
    () => ({
      room,
      setRoom,
      connectToRoom,

      connecting,
      error,
      clearRoomContext,
    }),
    [room, setRoom, connectToRoom, connecting, error, clearRoomContext]
  );

  return (
    <RoomContext.Provider value={contextValue}>{children}</RoomContext.Provider>
  );
};
