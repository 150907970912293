import React, {
  ReactNode,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";

export interface IdentityContextValue {
  deafUserIdentity: string;
  setDeafUserIdentity: (name: string) => void;
  remoteVideoFlag: boolean | null;
  setRemoteVideoFlag: (value: boolean) => void;
}

export const IdentityContext = React.createContext<IdentityContextValue>({
  deafUserIdentity: "",
  setDeafUserIdentity: () => {},
  remoteVideoFlag: null,
  setRemoteVideoFlag: () => {},
});

export const IdentityConsumer = IdentityContext.Consumer;

export const IdentityProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [remoteVideoFlag, setStateRemoteVideoTrackEnabled] = useState(false);

  const [deafUserIdentity, setDeafUserIdentityState] = useState("");

  const setDeafUserIdentity = useCallback(
    (identity: React.SetStateAction<string>) => {
      if (identity) {
        setDeafUserIdentityState(identity);
      }
    },
    [setDeafUserIdentityState]
  );

  const setRemoteVideoFlag = useCallback(
    (value: boolean | ((prevState: boolean) => boolean)) => {
      setStateRemoteVideoTrackEnabled(value);
    },
    [setStateRemoteVideoTrackEnabled]
  );

  const IdentityContextValue = useMemo(
    () => ({
      remoteVideoFlag,
      setRemoteVideoFlag,
      deafUserIdentity,
      setDeafUserIdentity,
    }),
    [remoteVideoFlag, setRemoteVideoFlag, deafUserIdentity, setDeafUserIdentity]
  );

  return (
    <IdentityContext.Provider value={IdentityContextValue}>
      {children}
    </IdentityContext.Provider>
  );
};

export const useIdentity = () => useContext(IdentityContext);
