/* eslint-disable max-classes-per-file */
export class MessagingError extends Error {
    name = 'Messaging Error';

    constructor(public message: string) {
        super();
    }
}

export class CallError extends Error {
    name = 'Call Error';

    constructor(public message: string, public type: string) {
        super();
    }
}
