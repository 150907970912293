import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LocalStorage from "src/call-sdk/lib/local-storage";
import StorageConstants from "src/config/storage.constants";
import ErrorBoundary from "./ErrorBoundary";
import { CircularProgress, Stack, Typography } from "@mui/material";
import {
  NoVideoViewContainer,
  SelfViewContainer,
} from "./selfView/VideoContainerStyles";
import VideoOffDenied from "../assets/Room/video-denied.svg";

const ViewContainer = styled.div`
  background-color: black;
  height: 100vh;
  disply: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  widht: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .oops {
    color: var(--white-100, #fff);
    font-feature-settings: "clig" off, "liga" off;
    /* Heading/H4 Bold */
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 118%; /* 37.76px */
    margin-bottom: 16px;
  }
`;

const SpinnerContainer = styled.div`
  flex: 1; /* Allow the spinner container to take all available vertical space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add some bottom margin to create space at the bottom */
`;

const Text = styled(Typography)`
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
  color: white;
`;

const Reconnecting = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = LocalStorage.get("hasReloaded") === "true";

    if (!hasReloaded) {
      // Reload the page only once
      LocalStorage.set("hasReloaded", "true");
      window.location.reload();
    } else {
      changeAddressBarColor();

      setTimeout(() => {
        const qrId = LocalStorage.get(StorageConstants.QR_ID);
        navigate({ pathname: `/qr-verifying/${qrId}` }, { replace: true });
      }, 4000); // Adjust the delay as needed
    }
  }, [navigate]);

  const changeAddressBarColor = () => {
    let metas = document.head.getElementsByTagName("meta");
    metas[2].content = "#000000";
    document.body.style.backgroundColor = "#000000";
  };

  return (
    <ErrorBoundary>
      <ViewContainer>
        <SelfViewContainer>
          <NoVideoViewContainer style={{ backgroundColor: "red" }}>
            <img
              src={VideoOffDenied}
              alt="No Camera"
              style={{ height: "8vh" }}
            />
          </NoVideoViewContainer>
        </SelfViewContainer>

        <ContentContainer>
          <SpinnerContainer>
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
              <CircularProgress color="success" />
            </Stack>
            <Text>Refreshing...</Text>
          </SpinnerContainer>
        </ContentContainer>
      </ViewContainer>
    </ErrorBoundary>
  );
};

export default Reconnecting;
