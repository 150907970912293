import React from "react";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Config from "../../config";

// Define an interface for the styled component props
interface ContainerProps {
  isSmallScreenLaptop: boolean;
}

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
});

const LogoImage = styled("img")({
  width: "220px",
  minWidth: "130px",
  height: "80px",
  marginRight: "10px",
});

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "white",
}));

const Content = styled("div")<ContainerProps>(({ isSmallScreenLaptop }) => ({
  textAlign: isSmallScreenLaptop ? "center" : "left",
  fontFamily: "Source Sans Pro, sans-serif",
  position: "fixed",
  top: "40vh",
}));

const Heading = styled("h2")<ContainerProps>(({ isSmallScreenLaptop }) => ({
  color: "black",
  fontSize: isSmallScreenLaptop ? "24px" : "32px",
  marginBottom: "10px",
}));

function DesktopPageError() {
  const isSmallScreenLaptop = useMediaQuery("(max-width: 1024px)");

  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];

  return (
    <>
      <HeaderContainer id="header-container">
        <LogoImage
          src={regionConfig?.appLogoImageSrc ?? ""}
          alt="Convo Logo"
          id="logo-image"
        />
      </HeaderContainer>
      <Container>
        <Content isSmallScreenLaptop={isSmallScreenLaptop}>
          <Heading
            isSmallScreenLaptop={isSmallScreenLaptop}
            id="error-heading"
            tabIndex={0}
          >
            Please try accessing the link on your mobile device or tablet by scanning the QR code.
          </Heading>
        </Content>
      </Container>
    </>
  );
}

export default DesktopPageError;
