import { Client, IMessage } from "@stomp/stompjs";
import { getRegisterDeviceOptionsForWebsocket } from "../helper/register-device";
import { MessagePayload, UUID } from "../../types";
import { CONNECTION_FAILED } from "../config/constants";
import * as Sentry from "@sentry/browser";

export interface WebSocketClientOptions {
  url: string;
  debug?: (message: string) => void;
  authToken: string;
  userId: UUID;
  deviceId: UUID;
}

export type OnRegisterDeviceCallback = (isDeviceRegistered: boolean) => void;
export type OnMessageCallback = (data: any) => void;
export type OnErrorCallback = (error: any) => void;

export default class WebSocketClient {
  private client: Client | null = null;
  private authToken: string;
  private userId: UUID | null = null;
  private deviceId: UUID;
  private onRegisterDeviceCallback: OnRegisterDeviceCallback | null = null;
  private onMessageCallback: OnMessageCallback | null = null;
  private onErrorCallback: OnErrorCallback | null = null;

  constructor({
    url,
    authToken,
    userId,
    deviceId,
    debug = () => {},
  }: WebSocketClientOptions) {
    this.userId = userId || null; // Set userId to null if it is null or undefined
    this.authToken = authToken || ""; // Set authToken to an empty string if it is null or undefined
    this.deviceId = deviceId;

    this.client = new Client({
      brokerURL: url,
      debug,
      reconnectDelay: 10000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      connectHeaders: this.getHeader(),
    });
  }

  onMessage(onMessageCallback: OnMessageCallback) {
    this.onMessageCallback = onMessageCallback;
  }

  onError(onErrorCallback: OnErrorCallback) {
    this.onErrorCallback = onErrorCallback;
  }

  onRegisterDevice(onRegisterDeviceCallback: OnRegisterDeviceCallback) {
    this.onRegisterDeviceCallback = onRegisterDeviceCallback;
  }

  private registerDevice() {
    if (!this.client || !this.userId || !this.deviceId) {
      this.onErrorCallback?.(CONNECTION_FAILED);
      return;
    }

    if (this.client.connected) {
      this.client.publish({
        destination: "/app/registerDesktopDevice",
        headers: this.getHeader(),
        body: JSON.stringify(
          getRegisterDeviceOptionsForWebsocket({
            userId: this.userId,
            deviceId: this.deviceId,
          })
        ),
      });

      this.onRegisterDeviceCallback?.(true);
    }
  }

  connect() {
    if (!this.client) {
      this.captureError(CONNECTION_FAILED);
      return;
    }

    this.client.activate();

    this.client.onWebSocketError = () => {
      this.captureError(CONNECTION_FAILED);
    };

    this.client.onConnect = () => {
      this.subscribe();

      this.registerDevice();
    };
  }

  private getHeader() {
    return {
      Authorization: `Bearer ${this.authToken}`,
    };
  }

  private handleSubscriptionMessage(message: IMessage) {
    const { body } = message;

    if (body) {
      const messagePayload = JSON.parse(body) as MessagePayload;
      if (messagePayload?.data) {
        this.onMessageCallback?.(messagePayload.data);
      }
    }
  }

  private subscribe() {
    if (this.client && this.userId && this.deviceId) {
      this.client.subscribe(
        `/user/${this.userId}/${this.deviceId}/notification`,
        (message) => this.handleSubscriptionMessage(message),
        this.getHeader()
      );
    }
  }

  destroy() {
    if (this.client?.connected) {
      this.client.deactivate();
    }
    this.client = null;
    this.userId = null;
    this.authToken = "";
    this.onMessageCallback = null;
    this.onErrorCallback = null;
    this.onRegisterDeviceCallback = null;
  }

  private captureError(errorMsg: string) {
    Sentry.captureMessage(errorMsg); // Capture the error message
    this.onErrorCallback?.(errorMsg);
  }
}
